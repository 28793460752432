import type { AnswerCreateDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { QuestionDto } from '../models';
import { PollQuestionDto } from '@proxy/shared';

@Injectable({
  providedIn: 'root',
})
export class AnswerService {
  apiName = 'ZenBoss';

  answerQuestion = (input: AnswerCreateDto) =>
    this.restService.request<any, QuestionDto>({
      method: 'POST',
      url: '/api/zen-boss/answers/question',
      body: input,
    },
    { apiName: this.apiName });
    
    answerPollQuestion = (input: AnswerCreateDto) =>
    this.restService.request<any, PollQuestionDto>({
      method: 'POST',
      url: '/api/zen-boss/answers/poll-question',
      body: input,
    },
    { apiName: this.apiName });
  constructor(private restService: RestService) {}
}
