<ng-container *ngIf="currentUser$ | async as user">
  <ng-template #loginBtn>
    <div class="d-flex p-3">
      <div>
        <button role="button" class="btn btn-primary" style="width: 130px; font-size: 12px;" (click)="navigateToLogin()">
          {{ 'AbpAccount::Log in' | abpLocalization }}</button>
      </div>
      <div class="ms-2">
        <button role="button" class="btn btn-primary" style="width: 130px; font-size: 12px;" (click)="navigateToRegister()">
          {{ 'AbpAccount::Create account' | abpLocalization }}</button>
      </div>
    </div>
  </ng-template>
  <div class="dropdown btn-group" ngbDropdown>
    <ng-container *ngIf="user.isAuthenticated; else loginBtn">
      <ul class="navbar-nav toolbar-nav">

        <ng-container *ngFor="let item of navItems.items$ | async; trackBy: trackByFn">
          <ng-container *ngIf="item.visible()">
            <li class="nav-item d-flex align-items-center" *abpPermission="item.requiredPolicy">

              <ng-container *ngIf="item.component; else htmlTemplate" [ngComponentOutlet]="item.component"
                [ngComponentOutletInjector]="item | toInjector"></ng-container>

              <ng-template #htmlTemplate>
                <ul [innerHTML]="item.html" (click)="item.action ? item.action() : null"></ul>
              </ng-template>

            </li>
          </ng-container>

        </ng-container>
      </ul>
    </ng-container>
  </div>
</ng-container>