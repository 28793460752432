import { Component, OnInit, Input} from '@angular/core';
import { AnswerDto } from '@proxy/questions/answers';


@Component({
  selector: 'lib-answers',
  templateUrl: './answers.component.html',
  styleUrls: ['./answers.component.css']
})
export class AnswersComponent implements OnInit {
  @Input() answers = {} as AnswerDto[];
  answer = {} as AnswerDto;
  constructor(
    
  ) { }

  ngOnInit(): void {
  } 
}
