import { AuditedEntityDto } from '@abp/ng.core';

export class ZenBossTagDto extends AuditedEntityDto<string> {
  value: number;
  grouping: ZenBossTagGroupongDto;
  display: string;  

  constructor(initialValues: Partial<ZenBossTagDto> = {}) {
    super(initialValues);
  }
}

export class ZenBossTagGroupongDto extends AuditedEntityDto<string> {
  value: number;
  display: string;

  constructor(initialValues: Partial<ZenBossTagGroupongDto> = {}) {
    super(initialValues);

  }
}

export const SECTOR_GROUP: ZenBossTagGroupongDto = { value: 1, display: 'Sector' };
export const SECTOR_TAGS: ZenBossTagDto[] = [
  { id:'1', value: 1, display: 'General', grouping: SECTOR_GROUP},
  { id:'2', value: 2, display: 'Legal', grouping: SECTOR_GROUP},
  { id:'3', value: 3, display: 'Tourism', grouping: SECTOR_GROUP},
  { id:'4', value: 4, display: 'Construction', grouping: SECTOR_GROUP},
  { id:'5', value: 5, display: 'Petroleum', grouping: SECTOR_GROUP},
  { id:'6', value: 6, display: 'Finance', grouping: SECTOR_GROUP},
  { id:'7', value: 7, display: 'Forestry', grouping: SECTOR_GROUP},
  { id:'8', value: 8, display: 'ITC', grouping: SECTOR_GROUP},
  { id:'9', value: 9, display: 'Defence', grouping: SECTOR_GROUP},
  { id:'10', value: 10, display: 'CEE', grouping: SECTOR_GROUP},
  { id:'11', value: 11, display: 'MAC', grouping: SECTOR_GROUP},
  { id:'12', value: 12, display: 'Mining', grouping: SECTOR_GROUP},
  { id:'13', value: 13, display: 'Property', grouping: SECTOR_GROUP},
  { id:'14', value: 14, display: 'Transport', grouping: SECTOR_GROUP}
];

export const ELEMENT_GROUP: ZenBossTagGroupongDto = { value: 2, display: 'Elements' };
export const ELEMENT_TAGS: ZenBossTagDto[] = [
  { id:'1', value: 1, display: 'Ownership', grouping: ELEMENT_GROUP},
  { id:'2', value: 2, display: 'Management control', grouping: ELEMENT_GROUP},
  { id:'3', value: 3, display: 'Enterprise development', grouping: ELEMENT_GROUP},
  { id:'4', value: 4, display: 'Supplier development', grouping: ELEMENT_GROUP},
  { id:'5', value: 5, display: 'Procurement', grouping: ELEMENT_GROUP},
  { id:'6', value: 6, display: 'Socio economic development', grouping: ELEMENT_GROUP},  
  { id:'6', value: 6, display: 'Skills development', grouping: ELEMENT_GROUP},  
];

export const COUNCIL_GROUP: ZenBossTagGroupongDto = { value: 3, display: 'Council' };
export const COUNCIL_TAGS: ZenBossTagDto[] = [
  { id:'1', value: 1, display: 'AGRI BEE', grouping: COUNCIL_GROUP},
  { id:'2', value: 2, display: 'CSCC', grouping: COUNCIL_GROUP},
  { id:'3', value: 3, display: 'MAC SA', grouping: COUNCIL_GROUP},
  { id:'4', value: 4, display: 'LPC', grouping: COUNCIL_GROUP},
  { id:'5', value: 5, display: 'NDIC', grouping: COUNCIL_GROUP},
  { id:'6', value: 6, display: 'TOMSA', grouping: COUNCIL_GROUP},  
  { id:'7', value: 7, display: 'SAPIA', grouping: COUNCIL_GROUP},
  { id:'8', value: 8, display: 'FSTC', grouping: COUNCIL_GROUP},
  { id:'9', value: 9, display: 'FSCC', grouping: COUNCIL_GROUP},
  { id:'11', value: 10, display: 'ICTSC', grouping: COUNCIL_GROUP},
  { id:'12', value: 11, display: 'Minerals Council', grouping: COUNCIL_GROUP},
  { id:'13', value: 12, display: 'PSCC', grouping: COUNCIL_GROUP},
  { id:'14', value: 13, display: 'TSCC', grouping: COUNCIL_GROUP},
];

export const LEGISLATOR_GROUP: ZenBossTagGroupongDto = { value: 4, display: 'Legislator' };
export const LEGISLATOR_TAGS: ZenBossTagDto[] = [
  { id:'1', value: 1, display: 'DTIC', grouping: LEGISLATOR_GROUP},
  { id:'2', value: 2, display: 'SANAS', grouping: LEGISLATOR_GROUP},
  { id:'3', value: 3, display: 'BEE Commission', grouping: LEGISLATOR_GROUP},
];
