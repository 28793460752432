import { NgModule, NgModuleFactory, ModuleWithProviders } from '@angular/core';
import { CoreModule, LazyModuleFactory } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { CompnetRoutingModule } from './compnet-routing.module';
import { PrivacyPolicyComponent } from '../components/privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [PrivacyPolicyComponent],
  imports: [CoreModule, ThemeSharedModule, CompnetRoutingModule],
  exports: [],
})
export class CompnetModule {
  static forChild(): ModuleWithProviders<CompnetModule> {
    return {
      ngModule: CompnetModule,
      providers: [],
    };
  }

  static forLazy(): NgModuleFactory<CompnetModule> {
    return new LazyModuleFactory(CompnetModule.forChild());
  }
}
