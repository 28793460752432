import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  AgencyDto,
  AnswerRequestDto,
  PollAnswerDto,
  PollQuestionDto,
  PollQuestionServiceProxy
} from '@proxy/shared';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'lib-answer-request',
  templateUrl: './answer-request.component.html',
  styleUrls: ['./answer-request.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnswerRequestComponent implements OnInit {
  pollKey: string = '';
  poll: PollQuestionDto;
  agency: AgencyDto;
  answerRequest: AnswerRequestDto;
  pollAnswer: PollAnswerDto;
  initialPollAnswerId: string = '';
  previouslyAnsweredOption: string = '';
  hasBeenAnswered: boolean = false;
  answeredInPreviousSession: boolean = false;
  isPollClosed: boolean = false;

  constructor(
    public readonly pollQuestionService: PollQuestionServiceProxy,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.pollKey = this.route.snapshot.queryParamMap.get('pollKey');

    this.pollQuestionService
      .getByPollKey(this.pollKey)
      .subscribe(result => {
        this.poll = result.poll;
        this.agency = result.agency;
        this.pollAnswer = _.find(this.poll.pollAnswers, (answer) => {
          return answer.hashKey == this.pollKey;
        });
        this.initialPollAnswerId = this.pollAnswer.answerOptionId;
        if (this.initialPollAnswerId != null && this.initialPollAnswerId != '00000000-0000-0000-0000-000000000000') {
          this.answeredInPreviousSession = true;
          this.previouslyAnsweredOption = _.find(this.poll.answerOptions, (answerOption) => {
            return answerOption.id == this.pollAnswer.answerOptionId;
          }).answer;
        }

        if (this.poll.closedDate && moment().isAfter(this.poll.closedDate)) {
          this.isPollClosed = true;
        } else if (!this.poll.closedDate && moment().isAfter(this.poll.autoCloseDate)) {
          this.isPollClosed = true;
        }
      });
  }

  onPollAnswerChange(event) {
    this.pollAnswer.answerOptionId = event.target.value;
  }

  submitPollAnswerForm() {
    this.pollQuestionService.updatePollAnswer(this.pollAnswer)
      .subscribe(() => {
        this.hasBeenAnswered = true;
      });
  }
}
