<div class="poll-question" *ngIf="pollQuestion" [ngClass]="isDraft && isList ? 'draft': ''">
  <ng-container class="">
    <div class="poll-question__detail">
      <div class="poll-question__detail__header">
        <lib-status-badge [poll]="pollQuestion" [controlClass]="'poll'"></lib-status-badge>
        <ng-container *ngIf="isPollList && !isDraft && agencies">
          <button
            *abpPermission="'ZenBoss.PollQuestions.ViewResults'"
            id="show-results"
            class="poll-question__detail__title__stats-list__stat-badge edit-button"
            type="button"
            (click)="showForm();$event.stopPropagation()"
          >
            <i class="fa fa-solid fa-clipboard-list me-2"></i>
            View Results
          </button>
        </ng-container>
      </div>
      <div class="poll-question__detail__title">
        <div class="poll-question__detail__title__text">
          {{ pollQuestion.title }}
        </div>
        <div *ngIf="isList && !isDraft" class="poll-question__detail__title__stats-list">
          <div class="poll-question__detail__title__stats-list__stat-badge vote-up"
               [ngClass]="pollQuestion.voteUpCount > 0 ? 'has-votes': ''">
            <i class="fa-solid fa-thumbs-up"></i>
            <div class="poll-question__detail__title__stats-list__stat-badge__value">
              {{ pollQuestion.voteUpCount }} votes
            </div>
          </div>
          <div class="poll-question__detail__title__stats-list__stat-badge vote-down"
               [ngClass]="pollQuestion.voteDownCount > 0 ? 'has-votes': ''">
            <i class="fa-solid fa-thumbs-down"></i>
            <div class="poll-question__detail__title__stats-list__stat-badge__value">
              {{ pollQuestion.voteDownCount }} votes
            </div>
          </div>
          <div class="poll-question__detail__title__stats-list__stat-badge comments">
            <i class="fa-solid fa-comment-question"></i>
            <div class="poll-question__detail__title__stats-list__stat-badge__value">
              {{ pollQuestion.answers.length }} comments
            </div>
          </div>
          <div class="poll-question__detail__title__stats-list__stat-badge views">
            <i class="fa-solid fa-eye"></i>
            <div class="poll-question__detail__title__stats-list__stat-badge__value">
              {{ pollQuestion.views }} views
            </div>
          </div>
        </div>
        <div *ngIf="isList && isDraft" class="poll-question__detail__title__stats-list">
          <button
            id="create"
            class="poll-question__detail__title__stats-list__stat-badge edit-button"
            type="button"
            [routerLink]="routerLink"
            routerLinkActive="active"
          >
            <i class="fa fa-solid fa-edit me-2"></i>
            <span class="">{{ 'ZenBoss::PollQuestion.Edit' | abpLocalization }}</span>
          </button>
        </div>
      </div>
      <div *ngIf="!isList && !isDraft" class="poll-question__detail__stats">
        <div class="poll-question__detail__stats__stat-badge vote-up"
             [ngClass]="pollQuestion.voteUpCount > 0 ? 'has-votes': ''">
          <i class="fa-solid fa-thumbs-up"></i>
          <div class="poll-question__detail__stats__stat-badge__value">
            {{ pollQuestion.voteUpCount }} votes
          </div>
        </div>
        <div class="poll-question__detail__stats__stat-badge vote-down"
             [ngClass]="pollQuestion.voteDownCount > 0 ? 'has-votes': ''">
          <i class="fa-solid fa-thumbs-down"></i>
          <div class="poll-question__detail__stats__stat-badge__value">
            {{ pollQuestion.voteDownCount }} votes
          </div>
        </div>
        <div class="poll-question__detail__stats__stat-badge comments">
          <i class="fa-solid fa-comment-question"></i>
          <div class="poll-question__detail__stats__stat-badge__value">
            {{ pollQuestion.answers.length }} comments
          </div>
        </div>
        <div class="poll-question__detail__stats__stat-badge views">
          <i class="fa-solid fa-eye"></i>
          <div class="poll-question__detail__stats__stat-badge__value">
            {{ pollQuestion.views }} views
          </div>
        </div>
      </div>
      <div *ngIf="isClosed && !isDraft" class="poll-question__detail__results">
        <div class="d-flex flex-column">
          <ng-container *ngFor="let answerOption of pollQuestion.answerOptions">
            <div class="answer-option">
              <div class="mb-2">
                {{ answerOption.answer }}
              </div>
              <div class="progress">
                <div
                  class="progress-bar bg-primary"
                  [style]="getPercentage(answerOption)"
                  role="progressbar"
                  [attr.aria-valuenow]="getEvaluatedResult(answerOption)"
                  [attr.aria-valuemax]="totalVotes"
                  aria-valuemin="0"
                ></div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="d-flex flex-column">
          <div class="small short-desc">Total votes: {{ totalVotes }}</div>
        </div>
      </div>
      <div class="poll-question__detail__content" [ngClass]="isList ? '': 'show-line'">
        <div *ngIf="allowVoting" class="poll-question__detail__content__voting">
          <lib-poll-vote-up-down
            *abpPermission="'ZenBoss.PollQuestions.Vote'"
            [vote]="{
              value: pollQuestion.voteCount,
              voterId: currentUser.id,
              votes: pollQuestion.votes
            }"
            (voteRequest)="castVote($event)"
          ></lib-poll-vote-up-down>
        </div>
        <div
          class="poll-question__detail__content__text"
          [innerHTML]="pollQuestion.questionBody"
        ></div>
      </div>
    </div>
  </ng-container>
</div>

<abp-modal [(visible)]="isModalOpen" size="large">
  <ng-template #abpHeader>
    <div class="modal-title">Poll Results</div>
  </ng-template>

  <ng-template #abpBody>
    <div class="poll-title">
      {{pollQuestion.title}}
    </div>
    <div class="poll-answers">
      <ng-container *ngFor="let answerOption of pollQuestion.answerOptions;let indexOfOption=index;">
        <div class="answer-option option-{{indexOfOption}}">
          <div class="option-title">Answer {{indexOfOption + 1}}</div>
          <div class="option-value">{{answerOption.answer}}</div>
        </div>
      </ng-container>
    </div>
    <div class="answer-results" *ngIf="tableAnswers.length > 0; else loading">
      <div class="answer-result-row header">
        <div class="result-item agency">Agency</div>
        <div class="result-item option">Answer</div>
        <div class="result-item date-submitted">Submitted</div>
      </div>
      <ng-container *ngFor="let tableAnswer of tableAnswers">
        <div class="answer-result-row">
          <div class="result-item agency">{{tableAnswer.agencyName}}</div>
          <div class="result-item option">{{tableAnswer.answerOption}}</div>
          <div class="result-item date-submitted">{{tableAnswer.dateSubmitted}}</div>
        </div>
      </ng-container>
    </div>
    <ng-template #loading>
      <p class="no-results">
        No answers have been submitted for this poll.
      </p>
    </ng-template>

  </ng-template>
  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary float-end" abpClose>Close</button>
  </ng-template>
</abp-modal>
