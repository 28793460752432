import { EnvironmentService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css'],
})
export class PrivacyPolicyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
