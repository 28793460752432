import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ConfigStateService } from '@abp/ng.core';
import { VoteDto } from '../../../../questions/models/vote-dto';

@Component({
  selector: 'lib-poll-vote-up-down',
  templateUrl: './poll-vote-up-down.component.html',
  styleUrls: ['./poll-vote-up-down.component.scss'],
})
export class PollVoteUpDownComponent implements OnInit, OnChanges {
  @Input() vote = {} as VoteDto;
  @Output() voteRequest = new EventEmitter<VoteDto>();

  currentUser = this.configState.getOne('currentUser');

  constructor(
    private configState: ConfigStateService,
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(): void {
  }

  upVote() {
    this.voteRequest.emit({ value: 1 } as VoteDto);
  }

  downVote() {
    this.voteRequest.emit({ value: -1 } as VoteDto);
  }

  isCurrent() {
    if (this.vote.votes != undefined) {
      let userVote = this.vote.votes.find(x => x.voterId === this.currentUser.id);
      if (userVote === undefined) {
        return '';
      }
      if (userVote.value === 1) {
        return 'highlight-up';
      } else if (userVote.value === -1) {
        return 'highlight-down';
      }
    }
    return '';
  }

  private ok(): void {}
}
