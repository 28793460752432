import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ZenBossBaseControlComponent } from '../zen-boss-base-control/zen-boss-base-control.component';
import { AccountService } from '@volo/abp.ng.account/public';
import { ConfigStateService, ListService } from '@abp/ng.core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UserServiceProxy } from '@proxy/shared';
import { InsertEdit } from '../../questions/models/question-dto';
import { ToasterService } from '@abp/ng.theme.shared';

@Component({
  selector: 'lib-zen-boss-base-save-control',
  templateUrl: './zen-boss-base-save-control.component.html',
  styleUrls: ['./zen-boss-base-save-control.component.css'],
})
export class ZenBossBaseSaveControlComponent extends ZenBossBaseControlComponent implements OnInit {
  @Output() saved = new EventEmitter<any>();
  @Input() selectedItem = {} as any;
  @Input() mode = InsertEdit.Insert;

  public form: FormGroup;
  public editor = ClassicEditor;

  constructor(
    public readonly userService: UserServiceProxy,
    public readonly configState: ConfigStateService,
    public readonly accountService: AccountService,
    public readonly sanitizer: DomSanitizer,
    public readonly list: ListService,
    public readonly router: Router,
    public readonly fb: FormBuilder,
    public readonly toaster: ToasterService
  ) {
    super(userService, configState, accountService, sanitizer);
    this.editor.editorConfig = function (config) {
      config.height = '300px';
    };
  }

  ngOnInit(): void {}

  public hideForm(): void {
    if (this.form) {
      this.form.reset();
    }
  }

  public cancel() {
    this.saved.emit(this.selectedItem);
  }

  public get isInsert(): boolean {
    return this.mode === InsertEdit.Insert;
  }

  public get isEdit(): boolean {
    return this.mode === InsertEdit.Edit;
  }
}
