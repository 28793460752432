import { Component, OnInit } from '@angular/core';
import { UserServiceProxy } from '@proxy/shared';
import { ZenBossBaseControlComponent } from '../zen-boss-base-control/zen-boss-base-control.component';
import { AccountService } from '@volo/abp.ng.account/public';
import { ConfigStateService, ListService } from '@abp/ng.core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-zen-boss-base-list-control',
  templateUrl: './zen-boss-base-list-control.component.html',
  styleUrls: ['./zen-boss-base-list-control.component.css']
})
export class ZenBossBaseListControlComponent extends ZenBossBaseControlComponent implements OnInit {
  public selected = { };
  public pageSize = 5;

  constructor(
    public readonly userService: UserServiceProxy,
    public readonly configState: ConfigStateService,
    public readonly accountService: AccountService,
    public readonly sanitizer: DomSanitizer,
    public readonly list: ListService,
    public readonly router: Router,
  ) { 
    super(userService, configState, accountService, sanitizer);
  }

  ngOnInit(): void {
    this.list.maxResultCount = this.pageSize;
  }

  public scrollToTop() {

  }

  public view(id, navigateTo) {
    this.router.navigate([navigateTo, id]);
  }

  public showMore() {
    this.list.maxResultCount = this.list.maxResultCount + this.pageSize;
  }
}
