import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AskQuestionComponent, QuestionsComponent } from '@zen-boss';
import { AuthGuard, PermissionGuard } from '@abp/ng.core';
import { ViewQuestionComponent } from './view-question/view-question.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: QuestionsComponent,
  },
  {
    path: 'ask-question',
    component: AskQuestionComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: { requiredPolicy: 'ZenBoss.Questions.Ask' },

  },
  {
    path: 'view-question/:id',
    pathMatch: 'full',
    component: ViewQuestionComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuestionsRoutingModule {}
