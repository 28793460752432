<div [class]="getHighlighted()"
  >
  <ng-template #loading>
    <p class="placeholder-glow">
      <span class="placeholder rounded col-4"></span>
      <br />
      <span class="placeholder rounded w-50"></span>

    </p>
  </ng-template>
  <div
    *ngIf="profileLoaded == true && profilePictureLoaded == true; else loading"
    class="question-profile d-inline-flex justify-content-start align-items-center p-2 "
    [ngClass]="profile.transparent ? 'border-bottom' : 'border'" [style]="profile.transparent ? 'border-radius:0px!important;background:#FFF!important;' : ''" 
  >
    <div class="d-flex align-items-center">
      <div class="me-2">
        <img
          *ngIf="this.hasCustomProfilePicture == true"
          class="avatar"
          [ngClass]="profile.transparent ? getHighlighted() : 'big'"
          [src]="profile.picture"
          alt=""
        />
        <div
          *ngIf="this.hasCustomProfilePicture == false"
          class="avatar"
          [ngClass]="profile.transparent ? '' : 'big'"
        >
          <div class="avatar__letters">{{ profile.name[0] + profile.surname[0] }}</div>
        </div>
      </div>
      <div class="me-1 ms-0">
        <div class="small bold">{{ profile.user }}</div>
      </div>
      <div class="m-0">
        <div class="text-muted small duration">
          <i class="fa fa-regular fa-clock me-1" style="font-weight: 400 !important"></i>
          {{ profile.duration }}
        </div>
      </div>
    </div>
  </div>
</div>
