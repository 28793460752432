import { Component, OnInit } from '@angular/core';
import {BannerUploadServiceProxy,
  UserServiceProxy
} from '@proxy/shared';
import { AccountService } from '@volo/abp.ng.account/public';
import { ConfigStateService } from '@abp/ng.core';
import { DomSanitizer } from '@angular/platform-browser';
import {ZenBossBaseControlComponent} from "../shared/zen-boss-base-control/zen-boss-base-control.component";

@Component({
  selector: 'lib-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
})
export class BannersComponent extends ZenBossBaseControlComponent implements OnInit {
  constructor(
    public readonly userService: UserServiceProxy,
    public readonly configState: ConfigStateService,
    public readonly accountService: AccountService,
    public readonly bannerUploadService: BannerUploadServiceProxy,
    public readonly sanitizer: DomSanitizer,
  ) {
    super(userService, configState, accountService, sanitizer);
  }

  ngOnInit(): void {

  }
}
