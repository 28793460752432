import { AuthService, ConfigStateService, SessionStateService } from '@abp/ng.core';
import { NavItem, UserMenu, UserMenuService } from '@abp/ng.theme.shared';
import { Component, TrackByFunction } from '@angular/core';
import { Router } from '@angular/router';
import { eThemeLeptonComponents } from '@volo/abp.ng.theme.lepton'; 

@Component({
  selector: 'abp-current-user',
  // tslint:disable-next-line: component-max-inline-declarations
  templateUrl: 'current-user.component.html',
})
export class CurrentUserComponent {
  currentUser$ = this.configState.getOne$('currentUser');
  selectedTenant$ = this.sessionState.getTenant$();
  currentUserImageComponentKey = eThemeLeptonComponents.CurrentUserImage;

  trackByFn: TrackByFunction<UserMenu> = (_, element) => element.id;

  get smallScreen(): boolean {
    return window.innerWidth < 992;
  }

  constructor(
    public userMenu: UserMenuService,
    private authService: AuthService,
    private sessionState: SessionStateService,
    private configState: ConfigStateService,
    private router: Router
  ) {
    userMenu.removeItem("UserMenu.LinkedAccounts");
    userMenu.removeItem("UserMenu.SecurityLogs");
    userMenu.removeItem("UserMenu.MyAccount");
    userMenu.addItems([
      { 
        id: 'UserMenu.MyProfile', 
        order: 1, 
        html: '<a class="dropdown-item pointer d-flex d-flex-column">My profile</a>', 
        action: () => this.navigatetoMyProfile() 
      } as NavItem
    ]);
  }

  navigateToLogin() {
    this.authService.navigateToLogin();
  }

  navigatetoMyProfile() {
    this.router.navigate(["my-profile"]);
  }
}
