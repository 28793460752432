import { Component, Input, OnInit } from '@angular/core';
import { CommentDto, CommentService } from '@proxy/questions/answers/comments';
import { finalize, tap } from 'rxjs';

@Component({
  selector: 'lib-view-comment',
  templateUrl: './view-comment.component.html',
  styleUrls: ['./view-comment.component.css']
})
export class ViewCommentComponent implements OnInit {
  @Input() comment = { } as CommentDto;
  constructor(
    public readonly service: CommentService,
  ) { }

  ngOnInit(): void {
    
  }
}
