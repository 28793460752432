import type { EntityDto } from '@abp/ng.core';


export interface VoteDto extends EntityDto<string> {
  value: number;
  voterId: string;
  questionId:string;
  answerId?:string;
}

export interface VoteCreateDto  {
  value: number;
  questionId:string;
  answerId?:string;
}


