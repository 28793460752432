import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-zen-boss-footer',
  templateUrl: './zen-boss-footer.component.html',
  styleUrls: ['./zen-boss-footer.component.scss']
})
export class ZenBossFooterComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  navigateToPrivacyPolicy() {
    this.router.navigate(['/compnet/privacy-policy']);
  }

}
