import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard, PermissionGuard} from '@abp/ng.core';
import {PollQuestionsComponent} from './poll-questions.component';
import {CreatePollComponent} from './create-poll/create-poll.component';
import {ViewPollComponent} from './view-poll/view-poll.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: PollQuestionsComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {requiredPolicy: 'ZenBoss.PollQuestions.Ask'},

  },
  {
    path: 'create-poll',
    component: CreatePollComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {requiredPolicy: 'ZenBoss.PollQuestions.Ask'},
  },
  {
    path: 'create-poll/:id/:mode',
    component: CreatePollComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {requiredPolicy: 'ZenBoss.PollQuestions.Ask'},

  },
  {
    path: 'view-poll/:id',
    component: ViewPollComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PollQuestionsRoutingModule {
}
