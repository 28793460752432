import type { GetQuestionsInput, QuestionCreateDto, QuestionDto, QuestionUpdateDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  apiName = 'ZenBoss';

  ask = (input: QuestionCreateDto) =>
    this.restService.request<any, QuestionDto>({
      method: 'POST',
      url: '/api/zen-boss/questions',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/zen-boss/questions/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, QuestionDto>({
      method: 'GET',
      url: `/api/zen-boss/questions/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: GetQuestionsInput) =>
    this.restService.request<any, PagedResultDto<QuestionDto>>({
      method: 'GET',
      url: '/api/zen-boss/questions',
      params: { filterText: input.filterText, title: input.title, questionBody: input.questionBody, status: input.status, closeReason: input.closeReason, closedDateMin: input.closedDateMin, closedDateMax: input.closedDateMax, closedBy: input.closedBy, openedDateMin: input.openedDateMin, openedDateMax: input.openedDateMax, openedBy: input.openedBy, openedByName: input.openedByName, closedByName: input.closedByName, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  edit = (id: string, input: QuestionUpdateDto) =>
    this.restService.request<any, QuestionDto>({
      method: 'PUT',
      url: `/api/zen-boss/questions/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
