<div *ngIf="question" class="row">
  <div class="col-xl-10 col-lg-9 col-md-9 col-sm-12 col-xs-12">
    <div class="card-header question m-0 p-2 p-lg-4">
      <lib-profile-card [profile]="{ duration: question.duration, userId: question.openedBy }">
      </lib-profile-card>
    </div>
    <div class="card-body mb-2 py-0 px-3 px-lg-4 pb-lg-2">
      <h6 class="title">{{ question.title }}</h6>
      <div class="py-0 m-0" [innerHTML]="question.shortDesc"></div>
    </div>
  </div>
  <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <div class="kohde d-flex flex-wrap justify-content-start justify-content-md-end justify-content-lg-end mt-2 mt-lg-4 me-2 me-lg-4 mb-lg-3">
      <div class="mb-2 ms-2">
        <button class="btn btn-vote" disabled>
          <i class="fa-solid fa-thumbs-up" style="margin-right: 3px"></i>
          {{ question.voteUpCount }} <span class="label">votes</span>
        </button>
      </div>

      <div class="mb-2 ms-2">
        <button class="btn btn-vote" disabled>
          <i class="fa-solid fa-thumbs-down" style="margin-right: 3px"></i>
          {{ question.voteDownCount }} <span class="label">votes</span>
        </button>
      </div>

      <div class="mb-2 ms-2">
        <button class="btn btn-vote" disabled>
          <i class="fa-solid fa-comment-question" style="margin-right: 3px"></i>
          {{ question.answerCount }} <span class="label">answers</span>
        </button>
      </div>

      <div class="ms-2 mb-2">
        <button class="btn btn-vote" disabled>
          <i class="fa-solid fa-eye" style="margin-right: 3px"></i>
          {{ question.views }} <span class="label">views</span>
        </button>
      </div>
    </div>
  </div>
</div>
