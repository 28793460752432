import { Component, OnInit } from '@angular/core';
import { ListService, LocalizationService } from '@abp/ng.core';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '@abp/ng.theme.shared';
import { ActivatedRoute, Router } from '@angular/router';
import { ZenBossBaseControlComponent } from '../../shared/zen-boss-base-control/zen-boss-base-control.component';
import { AccountService } from '@volo/abp.ng.account/public';
import { ConfigStateService } from '@abp/ng.core';
import { DomSanitizer } from '@angular/platform-browser';
import { PollQuestionDto, PollQuestionServiceProxy, UserServiceProxy } from '@proxy/shared';
import { VoteService } from '@proxy/questions/votes';
import { InsertEdit } from '../../questions/models/question-dto';
import * as moment from 'moment';

@Component({
  selector: 'lib-view-poll',
  templateUrl: './view-poll.component.html',
  styleUrls: ['./view-poll.component.scss'],
  providers: [ListService, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
})
export class ViewPollComponent extends ZenBossBaseControlComponent implements OnInit {
  pageTitle: string = '';
  pollId: string;
  pollQuestion: PollQuestionDto;
  mode = InsertEdit.Edit;
  isClosed: boolean = false;

  constructor(
    public readonly userService: UserServiceProxy,
    public readonly pollService: PollQuestionServiceProxy,
    public readonly configState: ConfigStateService,
    public readonly accountService: AccountService,
    public readonly sanitizer: DomSanitizer,
    public readonly router: Router,
    public readonly toaster: ToasterService,
    private l: LocalizationService,
    private route: ActivatedRoute,
    private readonly voteService: VoteService
  ) {
    super(userService, configState, accountService, sanitizer);
  }

  ngOnInit(): void {
    this.pollId = this.route.snapshot.paramMap.get('id');
    this.pollService.pollQuestionsGET2(this.pollId).subscribe(result => {
      this.pollQuestion = result;
      if(moment().isAfter(moment(this.pollQuestion.autoCloseDate)) || moment().isAfter(moment(this.pollQuestion.closedDate))){
        this.isClosed = true;
      }
    });
  }

  postAnswer(poll) {
    this.pollQuestion = poll;
  }
}
