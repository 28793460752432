<ng-container *ngIf="isLoaded; else loading">
  <div class="form-check mb-2">
    <input
      type="checkbox"
      class="form-check-input"
      id="two-factor-enabled"
      [(ngModel)]="isTwoFactorEnabled"
      [loading]="inProgress"
      (click)="setTwoFactorEnabled();"
    />
    <label class="form-check-label" for="two-factor-enabled">Two factor security enabled</label>
  </div>
  
</ng-container>

<ng-template #loading>
  <div [abpLoading]="true"></div>
</ng-template>
