<div *ngIf="vote" class="kohde-vote">
    <div [ngClass]="isCurrent()" class="d-flex flex-column">
        <div class="align-self-center">
            <button class="btn kohde-vote-btn up-vote" (click)="upVote()">
                <i class="fa-solid fa-thumbs-up"></i>
            </button>
        </div>
        <div class="align-self-center">
            <div class="my-2 kohde-vote-counter">
                 {{ vote.votes.length }}
            </div>
        </div>
        <div class="align-self-center">
            <button class="btn kohde-vote-btn down-vote" (click)="downVote()">
                <i class="fa-solid fa-thumbs-down mt-2"></i>
            </button>
        </div>
    </div>
</div>
