import { Environment } from '@abp/ng.core';

const baseUrl = 'https://app.compnet.info';

const oAuthConfig = {
  issuer: 'https://identity.compnet.info',
  redirectUri: baseUrl,
  clientId: 'ZenBoss_App',
  responseType: 'code',
  scope: 'offline_access ZenBoss openid email profile',
  requireHttps: true,
};

export const environment = {
  production: true,
  localization: {
    defaultResourceName: 'ZenBoss',
  },
  application: {
    baseUrl,
    name: 'ZenBoss',
    logoUrl: '',
  },
  oAuthConfig,
  apis: {
    default: {
      url: 'https://identity.compnet.info',
      rootNamespace: 'ZenBoss',
    },
    AbpAccountPublic: {
      url: oAuthConfig.issuer,
      rootNamespace: 'AbpAccountPublic',
    },
    ZenBoss: {
      url: 'https://api.compnet.info',
      rootNamespace: 'ZenBoss',
    },
  },
} as Environment;
