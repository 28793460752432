<form [formGroup]="form" (ngSubmit)="onSubmit()" [mapErrorsFn]="mapErrorsFn" validateOnSubmit>
  <div class="mb-3">
    <label class="form-label" for="current-password">{{
      'AbpIdentity::DisplayName:CurrentPassword' | abpLocalization
    }}</label
    ><span> * </span
    ><input
      type="password"
      id="current-password"
      class="form-control"
      formControlName="password"
      autofocus
    />
  </div>
  <div class="mb-3">
    <label class="form-label" for="new-password">{{
      'AbpIdentity::DisplayName:NewPassword' | abpLocalization
    }}</label
    ><span> * </span
    ><input type="password" id="new-password" class="form-control" formControlName="newPassword" />
  </div>
  <div class="mb-3">
    <label class="form-label" for="confirm-new-password">{{
      'AbpIdentity::DisplayName:NewPasswordConfirm' | abpLocalization
    }}</label
    ><span> * </span
    ><input
      type="password"
      id="confirm-new-password"
      class="form-control"
      formControlName="repeatNewPassword"
    />
  </div>

  <div class="d-flex justify-content-end mt-4" style="margin-bottom:-24px!important;">
    <abp-button
      iconClass="fa fa-check"
      buttonClass="btn btn-outline-primary me-2"
      buttonType="submit"
      [disabled]="form?.invalid"
      abpClose
      >Save</abp-button
    >
    <button type="button" class="btn btn-secondary me-2" abpClose>Cancel</button>
  </div>
</form>
