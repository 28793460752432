import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AnswerRequestComponent} from './answer-request.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AnswerRequestComponent,
  },
  {
    path: 'answer-request/:pollKey',
    pathMatch: 'full',
    component: AnswerRequestComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AnswerRequestRoutingModule {
}
