<ng-container *ngIf="comment">
  <div class="p-0 m-0 py-2 py-lg-4">
  <lib-profile-card    
    [profile]="{ userId: comment.commentBy, duration: comment.duration, transparent: true }"
  >
  </lib-profile-card>
</div>
  <div
    class="overflow-auto text-wrap m-0 ps-1 ps-lg-2"
    style="max-height: 300px!important"
    [(innerHTML)]="comment.commentBody"
  ></div>
</ng-container>
