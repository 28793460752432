import { Component, Input, OnInit } from '@angular/core';
import { ListService, LocalizationService } from '@abp/ng.core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '@abp/ng.theme.shared';
import { finalize, tap } from 'rxjs/operators';
import { QuestionService } from '../../proxy/questions/question.service';
import { Router } from '@angular/router';
import { ZenBossBaseSaveControlComponent } from '../../shared/zen-boss-base-save-control/zen-boss-base-save-control.component';
import { AccountService } from '@volo/abp.ng.account/public';
import { ConfigStateService } from '@abp/ng.core';
import { DomSanitizer } from '@angular/platform-browser';
import { QuestionCreateDto, UserServiceProxy } from '@proxy/shared';

@Component({
  selector: 'lib-ask-question',
  templateUrl: './ask-question.component.html',
  styleUrls: ['./ask-question.component.css'],
  providers: [ListService, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
})
export class AskQuestionComponent extends ZenBossBaseSaveControlComponent implements OnInit {
  pageTitle: string = '';
  selectedItem: any = { questionBody: '' };
  constructor(
    public readonly userService: UserServiceProxy,
    public readonly configState: ConfigStateService,
    public readonly accountService: AccountService,
    public readonly sanitizer: DomSanitizer,
    public readonly list: ListService,
    public readonly router: Router,
    public readonly service: QuestionService,
    public readonly fb: FormBuilder,
    public readonly toaster: ToasterService,
    private l: LocalizationService
  ) {
    super(userService, configState, accountService, sanitizer, list, router, fb, toaster);

    this.editor.editorConfig = function (config) {
      config.height = '300px';
    };
  }

  ngOnInit(): void {
    if (this.isInsert) {
      this.l.get('ZenBoss::Question.Ask').subscribe(x => (this.pageTitle = x));
    } else {
      this.pageTitle = '';
    }
    this.askQuestion();
  }

  askQuestion() {
    this.buildForm();
  }

  buildForm() {
    const { title, questionBody } = this.selectedItem || {};

    this.form = this.fb.group({
      title: [title ?? null, [Validators.required, Validators.maxLength(1000)]],
      questionBody: [questionBody ?? '', [Validators.required]],
      tags: ['' || null],
    });
  }

  ask() {
    if (this.form.invalid) return;

    const request = this.service.ask(this.selectedItem);

    request
      .pipe(
        finalize(() => this.okSave()),
        tap(() => this.hideForm())
      )
      .subscribe(this.list.get);
  }

  edit() {
    if (this.form.invalid) return;

    const request = this.service.edit(this.selectedItem.id, {
      title: this.selectedItem.title,
      questionBody: this.selectedItem.questionBody,
      views: this.selectedItem.views,
    });

    request
      .pipe(
        finalize(() => this.okSave()),
        tap(() => this.hideForm())
      )
      .subscribe(question => (this.selectedItem = question));
  }

  private okSave() {
    if (this.mode == 0) {
      this.toaster.success('Congrats, you have successfully posted a question.', 'New Question');
      this.router.navigate(['/']);
    } else {
      this.toaster.success(
        'Congrats, you have successfully updated your question.',
        'Edit Question'
      );
    }

    this.saved.emit(this.selectedItem);
  }

  showForm() {
    this.buildForm();
  }
}
