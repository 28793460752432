<div *ngIf="answer" class="panel panel-default d-flex m-0 p-0">
  <div class="card m-0 p-0 me-2 mb-4" [ngClass]="marker()"></div>
  <div class="card p-0 p-2 p-lg-4 flex-fill">
    <div class="row">
      <div class="col col-12">
        <div class="align-top">
          <lib-profile-card [profile]="{ userId: answer.answeredBy, duration: answer.duration }">
          </lib-profile-card>
        </div>
        <div class="d-flex justify-content-start my-2 my-lg-4 py-0 pb-lg-4" style="border-bottom: 1px solid #d9e2ec;">
          <div class="align-top ">
            <lib-vote-up-down
              *abpPermission="'ZenBoss.Answers.Vote'"
              [vote]="{
                value: answer.voteCount,
                userId: answer.answeredBy,
                votes: answer.votes,
                user: answer.answeredByName
              }"
              (voteRequest)="castVote($event)"
            >
            </lib-vote-up-down>
          </div>
          <div class="flex-fill m-0 mx-2 mx-lg-4" >
            <div
              class="text-wrap overflow-auto pb-2 kohde-answer"
              [(innerHTML)]="answer.answerBody"
            ></div>


          </div>
        </div>

        <div class="p-0 m-0">
          <div class="d-flex p-0 m-0">
            <button
              *abpPermission="'ZenBoss.Answers.Comment'"
              class="btn btn-small btn-outline-primary p-1 px-2 me-2 kohde"
              [hidden]="this.isVisible"
              (click)="toggle()"
            >
              Add a comment
            </button>
            <button
              *ngIf="answer.comments && answer.comments.length > 0"
              class="btn btn-small btn-outline-primary p-1 px-2 kohde"
              (click)="toggleComments()"
              [hidden]="this.isVisible"
            >
              <i
                [ngClass]="
                  this.isCommentsVisible
                    ? 'fa-solid fa-chevron-up me-2'
                    : 'fa-solid fa-chevron-down me-2'
                "
              ></i>
              {{ btnText + ' (' + answer.comments.length + ')' }}
            </button>
          </div>
          <lib-post-comment
            *abpPermission="'ZenBoss.Answers.Comment'"
            [hidden]="!this.isVisible"
            class="flex-fill p-0 m-0"
            [(answer)]="answer"
            (postCommentRequest)="postComment($event)"
            (cancelCommentRequest)="cancel($event)"
          ></lib-post-comment>
          <div class="p-0 m-0">
            <lib-comments
              class="p-0 m-0"
              [(comments)]="answer.comments"
              [(isVisible)]="this.isCommentsVisible"
            ></lib-comments>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
