import { EnvironmentService, ListService } from '@abp/ng.core';
import { ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AnswerDto } from '@proxy/questions/answers';
import { CommentCreateDto, CommentService } from '@proxy/questions/answers/comments';
import { finalize, tap } from 'rxjs';

@Component({
  selector: 'lib-post-comment',
  templateUrl: './post-comment.component.html',
  styleUrls: ['./post-comment.component.css'],
  providers: [ListService, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
})
export class PostCommentComponent implements OnInit {
  @Input() answer = { } as AnswerDto;
  @Output() postCommentRequest = new EventEmitter<AnswerDto>();
  @Output() cancelCommentRequest = new EventEmitter<AnswerDto>();

  public editor = ClassicEditor;

  form: FormGroup;
  comment = { commentBody: '' } as CommentCreateDto;

  constructor(
    public readonly list: ListService,
    private fb: FormBuilder,
    private confirmation: ConfirmationService,
    public readonly service: CommentService,
    private router: Router,
    private toaster: ToasterService,
    private environment: EnvironmentService,
  ) { }

  ngOnInit(): void {
    this.editor.editorConfig = function (config) {
      // misc options
      config.height = '300px';
    };
    this.postComment();
  }

  postComment() {
    this.buildForm();
  }

  buildForm() {
    const { commentBody } = this.comment || {};

    this.form = this.fb.group({
      commentBody: [commentBody ?? '', [Validators.required]],
    });

    this.comment.questionId = this.answer.questionId;
    this.comment.answerId = this.answer.id;
  }

  save() {
    if (this.form.invalid) return;

    this.comment.returnUrl = this.environment.getEnvironment().oAuthConfig.redirectUri;
    const request = this.service.commentAnswer(this.comment);

    request
      .pipe(
        finalize(() => this.okSave()),
        tap(() => this.hideForm())
      )
      .subscribe(answer => this.answer = answer);
  }

  private okSave() {
    this.toaster.success('You have successfully posted your comment.', '');
    this.postCommentRequest.emit(this.answer);
  }

  hideForm() {
    this.form.reset();
  }

  cancel() {
    this.cancelCommentRequest.emit(this.answer);
  }

}
