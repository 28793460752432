import {ConfigStateService} from '@abp/ng.core';
import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {VoteService} from '@proxy/questions/votes';
import {
  AgencyDto,
  AgencyServiceProxy,
  AnswerOptionDto,
  PollQuestionDto,
  PollQuestionStatus,
  UserServiceProxy
} from '@proxy/shared';
import {AccountService} from '@volo/abp.ng.account/public';
import {ZenBossBaseControlComponent} from '../../../zen-boss-base-control/zen-boss-base-control.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import {InsertEdit} from "../../../../questions/models/question-dto";
import {AgencyResultDto} from "./agency-result-dto";
import {Router} from "@angular/router";

@Component({
  selector: 'lib-poll-card',
  templateUrl: './poll-card.component.html',
  styleUrls: ['./poll-card.component.scss'],
})
export class PollCardComponent extends ZenBossBaseControlComponent implements OnInit {
  @Input() pollQuestion: PollQuestionDto = null;
  @Input() allowVoting: boolean = false;
  @Input() isList: boolean = false;
  @Input() agencies: AgencyDto[] = null;
  currentUser = this.configState.getOne('currentUser');
  countedAnswers: any;
  totalVotes: number;
  isClosed: boolean;
  isDraft: boolean;
  routerLink: string;
  isModalBusy = false;
  isModalOpen = false;
  tableAnswers: AgencyResultDto[] = [];
  isPollList: boolean = false;

  constructor(
    public readonly userService: UserServiceProxy,
    public readonly configState: ConfigStateService,
    public readonly accountService: AccountService,
    public readonly sanitizer: DomSanitizer,
    private readonly voteService: VoteService,
    private router: Router
  ) {
    super(userService, configState, accountService, sanitizer);
  }

  ngOnInit(): void {
    this.processResults();
    this.isPollList = this.router.url == '/polls';
    if (this.pollQuestion.status != PollQuestionStatus._0 && this.isPollList) {
      this.sortAgencyResponses();
    }
    if ((this.pollQuestion.closedDate && moment().isAfter(this.pollQuestion.closedDate)) || (!this.pollQuestion.closedDate && moment().isAfter(this.pollQuestion.autoCloseDate))) {
      this.isClosed = true;
    }
    this.isDraft = this.pollQuestion.status == PollQuestionStatus._0;

    if (this.isDraft) {
      this.routerLink = `/polls/create-poll/${this.pollQuestion.id}/${InsertEdit.Edit}`
    }

  }

  sortAgencyResponses() {
    let pollAnswers = _.filter(this.pollQuestion.pollAnswers, pollAnswer => {
      return pollAnswer.answerOptionId != '00000000-0000-0000-0000-000000000000'
    });

    _.forEach(pollAnswers, (pollAnswer) => {
      let thisResult: AgencyResultDto = {
        answerOption: _.indexOf(this.pollQuestion.answerOptions, _.find(this.pollQuestion.answerOptions, (answerOption) => answerOption.id == pollAnswer.answerOptionId), 0) + 1,
        agencyName: _.find(this.agencies, agency => agency.refNo == pollAnswer.agencyRefNo).name,
        dateSubmitted: pollAnswer.dateSubmitted?.format('DD-MM-YYYY')
      }
      this.tableAnswers.push(thisResult)
    })
  }

  processResults() {
    const totalAnswers = _.filter(this.pollQuestion.pollAnswers, pollAnswer => {
      return pollAnswer.answerOptionId != '00000000-0000-0000-0000-000000000000';
    });
    this.totalVotes = totalAnswers.length;

    this.countedAnswers = _.countBy(totalAnswers, pollAnswer => pollAnswer.answerOptionId);
  }

  getEvaluatedResult(answerOption: AnswerOptionDto): string {
    if (this.totalVotes > 0) {
      return this.countedAnswers[answerOption.id];
    }
  }

  getPercentage(answerOption: AnswerOptionDto) {
    if (this.totalVotes > 0) {
      return 'width:' + (this.countedAnswers[answerOption.id] / this.totalVotes) * 100 + '%';
    }
  }

  castVote(vote) {
    vote.questionId = this.pollQuestion.id;
    vote.userId = this.currentUser.id;
    this.voteService.castPollQuestionVote(vote).subscribe(poll => (this.pollQuestion = poll));
  }

  showForm() {
    this.isModalOpen = true;
  }

  hideForm() {
    this.isModalOpen = true;
  }
}
