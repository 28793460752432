<div *ngIf="answers" class="col col-12 m-0 p-0">
  <div class="panel-group">
    <div
      *ngFor="let answer of answers | orderBy: 'answeredDate':'desc'; let i = index"
      [attr.data-index]="i"
    >
      <lib-view-answer [answer]="answer" [index]="i"></lib-view-answer>
    </div>
  </div>
</div>
