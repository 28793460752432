import { NgModule, NgModuleFactory, ModuleWithProviders } from '@angular/core';
import { CoreModule, LazyModuleFactory } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NotificationsComponent } from './notifications.component';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { PageModule } from '@abp/ng.components/page';


@NgModule({
  declarations: [NotificationsComponent],
  imports: [CoreModule, ThemeSharedModule, NotificationsRoutingModule, PageModule],
  exports: [NotificationsComponent],
})
export class NotificationsModule { 
  static forChild(): ModuleWithProviders<NotificationsModule> {
    return {
      ngModule: NotificationsModule,
      providers: [],
    };
  }

  static forLazy(): NgModuleFactory<NotificationsModule> {
    return new LazyModuleFactory(NotificationsModule.forChild());
  }
}
