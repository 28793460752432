import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EnvironmentService, ListService } from '@abp/ng.core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { QuestionDto } from '@proxy/questions';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { finalize, tap } from 'rxjs/operators';
import { AnswerCreateDto, AnswerService } from '@proxy/questions/answers';
import { Router } from '@angular/router';
import { PollQuestionDto } from '@proxy/shared';

@Component({
  selector: 'lib-post-answer',
  templateUrl: './post-answer.component.html',
  styleUrls: ['./post-answer.component.css'],
  providers: [ListService, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
})
export class PostAnswerComponent implements OnInit {
  @Input() question? = {} as QuestionDto;
  @Input() pollQuestion? = {} as PollQuestionDto;
  @Output() postAnswerRequest = new EventEmitter<QuestionDto>();
  @Output() pollPostAnswerRequest = new EventEmitter<PollQuestionDto>();
  public editor = ClassicEditor;

  form: FormGroup;
  answer = { answerBody: '' } as AnswerCreateDto;
  isPoll: boolean = false;
  constructor(
    public readonly list: ListService,
    private fb: FormBuilder,
    private confirmation: ConfirmationService,
    public readonly service: AnswerService,
    private router: Router,
    private toaster: ToasterService,
    private environment: EnvironmentService
  ) {}

  ngOnInit(): void {
    this.editor.editorConfig = function (config) {
      // misc options
      config.height = '300px';
    };
    if(this.pollQuestion.id) this.isPoll = true;
    this.postAnswer();
  }

  postAnswer() {
    this.buildForm();
  }

  buildForm() {
    const { answerBody } = this.answer || { answerBody: '' };

    this.form = this.fb.group({
      answerBody: [answerBody ?? '', [Validators.required]],
    });

    if (this.isPoll) {
      this.answer.questionId = this.pollQuestion.id;
    } else {
      this.answer.questionId = this.question.id;
    }
  }

  save() {
    if (this.form.invalid) return;

    this.answer.returnUrl = this.environment.getEnvironment().oAuthConfig.redirectUri;
    if (this.isPoll) {
      const request = this.service.answerPollQuestion(this.answer);
      request
        .pipe(
          finalize(() => this.okSave()),
          tap(() => this.hideForm())
        )
        .subscribe(pollQuestion => (this.pollQuestion = pollQuestion));
    } else {
      const request = this.service.answerQuestion(this.answer);
      request
        .pipe(
          finalize(() => this.okSave()),
          tap(() => this.hideForm())
        )
        .subscribe(question => (this.question = question));
    }
  }

  private okSave() {
    if (this.isPoll) {
      this.toaster.success('You have successfully posted your comment.', '');
      this.pollPostAnswerRequest.emit(this.pollQuestion);
    } else {
      this.toaster.success('You have successfully posted your answer.', '');
      this.postAnswerRequest.emit(this.question);
    }
  }

  hideForm() {
    this.form.reset();
  }
}
