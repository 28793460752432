import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-how-to-ask-a-question',
  templateUrl: './how-to-ask-a-question.component.html',
  styleUrls: ['./how-to-ask-a-question.component.css']
})
export class HowToAskAQuestionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
