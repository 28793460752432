<!-- <lib-how-to-ask-a-question></lib-how-to-ask-a-question> -->
<abp-page [title]="pageTitle" class="col-auto p-0 m-0" >
  <form [formGroup]="form"  >
    <div class="row">
      <div class="col col-md-12">
        <div class="mb-3">
          <div class="form-group">
            <label for="question-title">{{ 'ZenBoss::Input:Title' | abpLocalization }}</label><span> * </span>
            <input
              [(ngModel)]="selectedItem.title"
              type="text"
              id="question-title"
              class="form-control"
              formControlName="title"
              autofocus
            />
          </div>
        </div>
        <div class="mb-3">
          <div class="form-group hide-border">
            <label for="question-questionBody">{{ 'ZenBoss::Input:Description' | abpLocalization }}</label><span> * </span>
            <ckeditor
              [editor]="editor"
              [data]="selectedItem.questionBody"
              [(ngModel)]="selectedItem.questionBody"
              [config]="{
                toolbar: {
                  items: [
                    'heading',
                    '|',                    
                    'bold',
                    'italic',                
                    '|',
                    'link',
                    '|',
                    'outdent',
                    'indent',
                    '|',
                    'bulletedList',
                    'numberedList',                    
                    '|',                    
                    'blockQuote',
                    '|',
                    'undo',
                    'redo'
                  ],
                  shouldNotGroupWhenFull: true
                }
              }"
              id="question-questionBody"
              class="form-control"
              formControlName="questionBody"
            >
            </ckeditor>
          </div>
        </div>
      </div>
    </div>
    <lib-tag-input *ngIf="isInsert"></lib-tag-input>
  </form>

  <div class="row">
    <div class="col col-md-12">
      <ng-container *ngIf="isInsert">
        <div class="d-flex justify-content-between">
          <div>
            <button type="button" class="btn btn-lg btn-secondary" routerLink="/">
              <!-- <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> -->
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
              {{ 'ZenBoss::Input:Back' | abpLocalization }}
            </button>
          </div>
          <div>
            <button
              *abpPermission="'ZenBoss.Questions.Ask'"
              class="btn btn-lg btn-primary"
              (click)="ask()"
              [disabled]="form.invalid"
            >
              <i class="fa fa-message-question mr-0"></i>
              {{ 'ZenBoss::Question.Post' | abpLocalization }}
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isEdit">
        <div class="d-flex justify-content-between">
          <div>
            <button type="button" class="btn btn-lg btn-secondary" (click)="cancel();">
              {{ 'ZenBoss::Input:Cancel' | abpLocalization }}
            </button>
          </div>
          <div>
            <button
              *abpPermission="'ZenBoss.Questions.Ask'"
              class="btn btn-lg btn-primary"
              (click)="edit();"
              type="button"
              [disabled]="form.invalid"
            >
              {{ 'ZenBoss::Input:Save' | abpLocalization }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</abp-page>
