<div *ngIf="question" class="card">
  <div class="card-header p-2 p-lg-4" style="border-bottom: 0">
    <!-- Header layout start -->
    <div class="d-flex flex-row">
      <!--  item 1 start -->
      <div>
        <lib-profile-card [profile]="{ duration: question.duration, userId: question.openedBy }">
        </lib-profile-card>
        <div>
          <h5 class="m-1" style="font-size: 28px;">{{ question.title }}</h5>
        </div>
        <div class="d-flex flex-wrap">
          <div class="me-2 mt-1">
            <button class="btn btn-vote" disabled>
              <i class="fa-solid fa-thumbs-up" style="margin-right: 3px"></i>
              {{ question.voteUpCount }} <span class="label">votes</span>
            </button>
          </div>
          <div class="me-2 mt-1">
            <button class="btn btn-vote" disabled>
              <i class="fa-solid fa-thumbs-down" style="margin-right: 3px"></i>
              {{ question.voteDownCount }} <span class="label">votes</span>
            </button>
          </div>
          <div class="me-2 mt-1">
            <button class="btn btn-vote" disabled>
              <i class="fa-solid fa-comment-question" style="margin-right: 3px"></i>
              {{ question.answerCount }} <span class="label">answers</span>
            </button>
          </div>
          <div class="mt-1">
            <button class="btn btn-vote" disabled>
              <i class="fa-solid fa-eye" style="margin-right: 3px"></i>
              {{ question.views }} <span class="label">views</span>
            </button>
          </div>
        </div>
      </div>
      <!-- item 1 end -->
    </div>
  </div>
  <!-- Header layout end -->

  <div class="card-body m-0 p-0">
    <!-- Body layout start -->
    <div
      class="d-flex flex-row m-0 p-0 pt-2 pt-lg-4 mx-2 mx-lg-4 mb-2 mb-lg-4"
      style="border-top: 1px solid #d9e2ec"
    >
      <!--  item 1 start -->
      <div class="">
        <lib-vote-up-down
          *abpPermission="'ZenBoss.Questions.Vote'"
          [vote]="{
            value: question.voteCount,
            userId: question.openedBy,
            votes: question.votes,
            user: question.openedByName
          }"
          (voteRequest)="castVote($event)"
        >
        </lib-vote-up-down>
      </div>
      <!--  item 1 end -->
      <!--  item 2 start -->
      <div class="mx-2 mx-lg-4">
        <div
          class="overflow-auto text-wrap"
          style="max-height: 350px !important"
          [innerHTML]="question.questionBody"
        ></div>
      </div>
      <!--  item 2 end -->
    </div>
    <div *ngIf="currentUser.id == question.openedBy" class="m-2 m-lg-4 d-flex justify-content-end">
      <button type="button" class="btn btn-secondary" (click)="showEditModal()">edit</button>
    </div>
    <!-- Body layout end -->
  </div>
</div>

<abp-modal
  [busy]="isEditModalBusy"
  [(visible)]="isEditModalOpen"
  [options]="{ size: 'xl' }"
>
  <ng-template #abpBody>
    <h5 style="color: #362cad">Edit your question</h5>
    <lib-ask-question 
      [(selectedItem)]="question"
      [mode]="mode"
      (saved)="hideEditModal($event);"
    ></lib-ask-question>
  </ng-template>
</abp-modal>
