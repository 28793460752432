<div *abpPermission="'ZenBoss.Questions.Answer'">
  <form *ngIf="question" [formGroup]="form">
    <div class="row">
      <div *ngIf="answer" class="col col-md-12">
        <div class="form-group">
          <label for="answer-answerBody">
            <h5 *ngIf="!isPoll">Your Answer</h5>
            <h5 *ngIf="isPoll">Your Comment</h5>
          </label>
          <ckeditor
            [(ngModel)]="answer.answerBody"
            [(data)]="answer.answerBody"
            [editor]="editor"
            [config]="{
              toolbar: ['undo', 'redo', '|', 'bold', 'italic', '|', 'link']
            }"
            id="answer-answerBody"
            class="form-control"
            formControlName="answerBody"
          >
          </ckeditor>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="d-flex justify-content-between my-2">
  <div>
    <button type="button" class="btn btn-secondary" routerLink="/">
      <i class="fa fa-arrow-left" aria-hidden="true"></i>
      {{ 'ZenBoss::Back' | abpLocalization }}
    </button>
  </div>
  <div *abpPermission="'ZenBoss.Questions.Answer'">
    <button *ngIf="!isPoll" class="btn btn-primary" (click)="save()" [disabled]="form.invalid">
      <i class="fa fa-message-question mr-0"></i>
      {{ 'AbpAccount::Post your answer' | abpLocalization }}
    </button>
    <button *ngIf="isPoll" class="btn btn-primary" (click)="save()" [disabled]="form.invalid">
      <i class="fa fa-message-question mr-0"></i>
      {{ 'AbpAccount::Post your comment' | abpLocalization }}
    </button>
  </div>
</div>
