import { ThisReceiver } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { CommentDto } from '@proxy/questions/answers/comments';

@Component({
  selector: 'lib-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {
  @Input() comments = { } as CommentDto[];
  @Input() isVisible = false as boolean;
  comment = { } as CommentDto;

  constructor() { 

  }

  ngOnInit(): void {
  
  }

}
