<abp-page *ngIf="question">
  <div class="row">
    <div class="col col-12">
      <lib-question-card-detailed [(question)]="question"></lib-question-card-detailed>
    </div>
  </div>

  <div *ngIf="question.answers && question.answers.length > 0" class="row">
    <div class="col col-12">
      <h5>{{ question.answers.length }} Answer(s)</h5>
      <lib-answers class="me-2 answers" [(answers)]="question.answers"></lib-answers>
    </div>
  </div>

  <div class="row">
    <div class="col col-12">
      <lib-post-answer
        [(question)]="question"
        (postAnswerRequest)="postAnswer($event)"
      ></lib-post-answer>
    </div>
  </div>
</abp-page>
