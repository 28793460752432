<div *abpPermission="'ZenBoss.Answers.Comment'">
  <form *ngIf="answer" [formGroup]="form">
    <div class="row">
      <div class="col col-md-12">
        <div class="form-group">
          <label for="comment-commentBody">
            <h5>Your Comment</h5>
          </label>
          <ckeditor
            [(ngModel)]="comment.commentBody"
            [(data)]="comment.commentBody"
            [editor]="editor"
            [config]="{
              toolbar: ['undo', 'redo', '|', 'bold', 'italic', '|', 'link']
            }"
            id="comment-commentBody"
            class="form-control"
            formControlName="commentBody"
          >
          </ckeditor>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="d-flex justify-content-between mt-2 mb-4">
  <div>
    <button type="button" class="btn btn-small btn-secondary ng-star-inserted" (click)="cancel()">
      <i class="fa fa-arrow-left" aria-hidden="true"></i>
      Cancel
    </button>
  </div>
  <div *abpPermission="'ZenBoss.Answers.Comment'">
    <button class="btn btn-small btn-primary" (click)="save()" [disabled]="form.invalid">
        Post your comment
    </button>
  </div>
</div>
