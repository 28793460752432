import { NgModule, NgModuleFactory, ModuleWithProviders } from '@angular/core';
import { CoreModule, LazyModuleFactory } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { PageModule } from '@abp/ng.components/page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { AnswerRequestRoutingModule } from './answer-request-routing.module';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { AnswerRequestComponent } from './answer-request.component';
import { API_BASE_URL, PollQuestionServiceProxy } from '@proxy/shared';
import { environment } from 'projects/dev-app/src/environments/environment';

@NgModule({
  declarations: [
    AnswerRequestComponent,
  ],
  imports: [
    CoreModule, 
    ThemeSharedModule, 
    AnswerRequestRoutingModule, 
    PageModule, 
    FormsModule, 
    ReactiveFormsModule,
    CommercialUiModule,     
    ReactiveFormsModule ,
    NgxValidateCoreModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    SharedModule,
  ],
  exports:[
    AnswerRequestComponent,
  ],
  providers:[
    PollQuestionServiceProxy,
    {
      provide: API_BASE_URL,
      useValue: environment.apis.ZenBoss.url
    },
  ],
})
export class AnswerRequestModule { 
  static forChild(): ModuleWithProviders<AnswerRequestModule> {
    return {
      ngModule: AnswerRequestModule,
      providers: [],
    };
  }

  static forLazy(): NgModuleFactory<AnswerRequestModule> {
    return new LazyModuleFactory(AnswerRequestModule.forChild());
  }
  
}

export function loadAnswerRequestModuleAsChild() {
  return Promise.resolve(AnswerRequestModule);
}
