<abp-page [title]="'Profile information'">
  <div *ngIf="profilePictureLoaded" class="container">
    <div class="row">
      <div class="profile-nav col-md-3">
        <div class="panel">
          <div class="user-heading round">
            <div class="profile">
              <lib-current-user-image
                *abpReplaceableTemplate="{
                  componentKey: currentUserImageComponentKey
                }"
              >
              </lib-current-user-image>
            </div>
            <h1>{{ this.profile.user }}</h1>
          </div>

          <nav class="nav flex-column nav-pills nav-gap-y-1">
            <a
              href="#profile"
              aria-expanded="false"
              data-bs-toggle="tab"
              class="nav-item nav-link has-icon nav-link-faded active"
            >
              Profile information
            </a>
            <a
              href="#questions"
              aria-expanded="false"
              aria-controls="questions"
              data-bs-toggle="tab"
              class="nav-item nav-link has-icon nav-link-faded"
            >
              My questions
            </a>
          </nav>
        </div>
      </div>
      <div class="col-md-9 tab-content p-4 p-md-4 p-xs-2 pt-0 mt-0">
        <div class="profile-info tab-pane fade mt-0 pt-4 pt-md-0" id="questions">
          <ng-template #alternateHeading>
            <h5 style="color: #362cad">You have not yet posted any questions</h5>
          </ng-template>
          <h5
            *ngIf="data.totalCount > 0; else alternateHeading"
            style="color: #362cad"
            class="mb-4"
          >
           <ng-template #firstQuestion>
            <span>My last question</span>
           </ng-template>
            <span *ngIf="data.totalCount > 1; else firstQuestion">My last {{ data.items.length }} question(s)</span>
          </h5>
          <ng-template #loading>
            <p class="placeholder-glow">
              <span class="placeholder rounded col-6"></span>
              <br />
              <span class="placeholder rounded w-75"></span>
              <br />
              <span class="placeholder rounded" style="width: 25%"></span>
            </p>
          </ng-template>
          <ng-container *ngIf="data.items; else loading">
            <div
              class="card question"
              *ngFor="let item of data.items; let i = index"
              [attr.data-index]="i"
              (click)="viewQuestion(item)"
            >
              <lib-question-card [question]="item"></lib-question-card>
            </div>
            <div class="row">
              <div class="col col-md-12">
                <div class="d-flex justify-content-center mb-2">
                  <button
                    class="btn btn-primary btn-lg me-2"
                    type="button"
                    routerLink="/ask-question"
                    routerLinkActive="active"
                  >
                    <i class="fa fa-message-question me-2"></i>
                    <span class="">Ask a question</span>
                  </button>

                  <ng-container *ngIf="data.totalCount > 0 && data.items.length === this.list.maxResultCount">
                    <button
                      class="btn btn-primary btn-lg"
                      type="button"
                      (click)="onShowMore()"
                      routerLinkActive="active"
                    >
                      <i class="fa fa-solid fa-loader me-2"></i>
                      <span class="">Show more questions</span>
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="profile-info tab-pane fade show active m-0 p-0" id="profile">
          <div class="m-0 p-0 pt-4 pt-md-0">
            <lib-profile-picture></lib-profile-picture>
            <h5 style="color: #362cad" class="mb-4">Profile information</h5>
            <lib-personal-settings-form></lib-personal-settings-form>

            <button
              *ngIf="!this.profile.isExternal"
              class="btn btn-outline-primary mt-4"
              (click)="showForm()"
            >
              <i class="fa-solid fa-unlock-keyhole me-2"></i>Change password
            </button>

            <div class="mt-4">
              <lib-two-factor-tab></lib-two-factor-tab>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</abp-page>

<abp-modal [busy]="isModalBusy" [(visible)]="isModalOpen">
  <ng-template #abpHeader>
    <h5 style="color: #362cad">Change password</h5>
  </ng-template>

  <ng-template #abpBody>
    <lib-change-password-form></lib-change-password-form>
  </ng-template>
  <ng-template #abpFooter> </ng-template>
</abp-modal>

<abp-modal
  [busy]="isAskQuestionModalBusy"
  [(visible)]="isAskQuestionModalOpen"
  [options]="{ size: 'xl' }"
>
  <ng-template #abpHeader>
    <h5 style="color: #362cad">Ask your quesion</h5>
  </ng-template>

  <ng-template #abpBody>
    <lib-ask-question></lib-ask-question>
  </ng-template>
  <ng-template #abpFooter> </ng-template>
</abp-modal>

<abp-modal
  [busy]="isQuestionModalBusy"
  [(visible)]="isQuestionModalOpen"
  [options]="{ size: 'xl' }"
>
  <ng-template #abpBody>
    <div class="row">
      <div class="col col-12">
        <lib-question-card-detailed
          *ngIf="selectedQuestion"
          [(question)]="selectedQuestion"
        ></lib-question-card-detailed>
      </div>
    </div>

    <div *ngIf="selectedQuestion.answers && selectedQuestion.answers.length > 0" class="row">
      <div class="col col-12">
        <h5>{{ selectedQuestion.answers.length }} Answer(s)</h5>
        <lib-answers class="me-2 answers" [(answers)]="selectedQuestion.answers"></lib-answers>
      </div>
    </div>
    <button type="button" class="btn btn-secondary float-end" abpClose>Close</button>
  </ng-template>

  <ng-template #abpFooter> </ng-template>
</abp-modal>
