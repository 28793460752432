import { NgModule, NgModuleFactory, ModuleWithProviders } from '@angular/core';
import { CoreModule, LazyModuleFactory } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { PageModule } from '@abp/ng.components/page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import {API_BASE_URL, BannerUploadServiceProxy} from '@proxy/shared';
import { environment } from 'projects/dev-app/src/environments/environment';
import {BannerUploadComponent} from "./banner-upload/banner-upload.component";
import {BannersRoutingModule} from "./banners-routing.module";
import {BannersComponent} from "./banners.component";

@NgModule({
  declarations: [
    BannersComponent,
    BannerUploadComponent
  ],
  imports: [
    CoreModule,
    ThemeSharedModule,
    PageModule,
    FormsModule,
    ReactiveFormsModule,
    CommercialUiModule,
    ReactiveFormsModule ,
    NgxValidateCoreModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    BannersRoutingModule,
    SharedModule,
  ],
  exports:[
    BannerUploadComponent,
  ],
  providers:[
    BannerUploadServiceProxy,
    {
      provide: API_BASE_URL,
      useValue: environment.apis.ZenBoss.url
    },
  ],
})
export class BannersModule {
  static forChild(): ModuleWithProviders<BannersModule> {
    return {
      ngModule: BannersModule,
      providers: [],
    };
  }

  static forLazy(): NgModuleFactory<BannersModule> {
    return new LazyModuleFactory(BannersModule.forChild());
  }
}

export function loadBannerUploadModuleAsChild() {
  return Promise.resolve(BannersModule);
}
