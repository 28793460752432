<ng-container *ngIf="comments">
  <div class="p-0 m-0"
    *ngFor="let comment of comments | orderBy: 'commentDate':'desc'; let i = index"
    [attr.data-index]="i"
  >
    <lib-view-comment
      style="width: 100% !important"
      [hidden]="!this.isVisible"
      [comment]="comment"
    ></lib-view-comment>
</div>
</ng-container>
