import { NgModule, NgModuleFactory, ModuleWithProviders } from '@angular/core';
import { CoreModule, LazyModuleFactory } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { ZenBossComponent } from './components/zen-boss.component';
import { ZenBossRoutingModule } from './zen-boss-routing.module';
import { ZenBossFooterComponent } from './zen-boss-footer/zen-boss-footer.component';
import { NavItemsComponent } from './nav-items/nav-items.component';
import { CurrentUserImageComponent } from './current-user-image/current-user-image.component';
import { CurrentUserComponent } from './current-user/current-user.component';

@NgModule({
  declarations: [
    ZenBossComponent,
    ZenBossFooterComponent,
    NavItemsComponent,
    CurrentUserImageComponent,
    CurrentUserComponent,
  ],
  imports: [
    CoreModule, 
    ThemeSharedModule, 
    ZenBossRoutingModule,
  ],
  exports: [
    ZenBossComponent,
  ],
  bootstrap: [
    CurrentUserComponent, 
    NavItemsComponent,
  ]
})
export class ZenBossModule {
  static forChild(): ModuleWithProviders<ZenBossModule> {
    return {
      ngModule: ZenBossModule,
      providers: [],
    };
  }

  static forLazy(): NgModuleFactory<ZenBossModule> {
    return new LazyModuleFactory(ZenBossModule.forChild());
  }
}
