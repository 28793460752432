import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import {BannerUploadServiceProxy} from "@proxy/shared";
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'lib-banner-display',
  templateUrl: './banner-display.component.html',
  styleUrls: ['./banner-display.component.scss'],
})
export class BannerDisplayComponent implements OnInit {
  @Input() isList: boolean = false;
  banner: any;
  constructor(
    public readonly bannerUploadService: BannerUploadServiceProxy,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.bannerUploadService.bannerUploadGET(this.isList).subscribe(result => {
      if(result){
        this.banner = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + result);
      }
    });
  }
}
