<lib-banner-display></lib-banner-display>
<abp-page *ngIf="pollQuestion" [title]="pageTitle" class="">
  <lib-poll-card class="poll-question-card" [pollQuestion]="pollQuestion" [allowVoting]="true"></lib-poll-card>
  <div class="poll-comments">
    <div *ngIf="pollQuestion.answers && pollQuestion.answers.length > 0" class="row">
      <div class="col col-12 poll-comments__comments">
        <h5>{{ pollQuestion.answers.length }} Comment(s)</h5>
        <lib-answers class="me-2 answers" [(answers)]="pollQuestion.answers"></lib-answers>
      </div>
    </div>
    <div>
      <lib-post-answer
        *abpPermission="'ZenBoss.PollQuestions.Comment'"
        [(pollQuestion)]="pollQuestion"
        (pollPostAnswerRequest)="postAnswer($event)"
      ></lib-post-answer>
    </div>
  </div>
</abp-page>
