import { Component, OnInit, Input } from '@angular/core';
import { QuestionDto, QuestionService } from '@proxy/questions';
import { VoteService } from '@proxy/questions/votes';
import { ConfigStateService } from '@abp/ng.core';
import { finalize, tap } from 'rxjs/operators';
import { ProfileDto } from '../../models/profile-dto';
import { InsertEdit } from '../../models/question-dto';

@Component({
  selector: 'lib-question-card-detailed',
  templateUrl: './question-card-detailed.component.html',
  styleUrls: ['./question-card-detailed.component.scss']
})
export class QuestionCardDetailedComponent implements OnInit {

  @Input() question = { } as QuestionDto;
  profile = { } as ProfileDto;
  currentUser = this.configState.getOne('currentUser');

  mode = InsertEdit.Edit;
  isEditModalBusy = false;
  isEditModalOpen = false;

  constructor(
    private configState: ConfigStateService,
    public readonly service: VoteService,
    public readonly questionService: QuestionService
  ) {
    
    }

  ngOnInit(): void {

  }

  castVote(vote) {  
    vote.questionId = this.question.id;
    const request = this.service.castQuestionVote(vote);

    request
      .pipe(
        finalize(() => this.ok()),
        tap(() => this.hide())
      )
      .subscribe(question => this.question = question);
  }

  hide() {

  }

  private ok() {

  }

  showEditModal() {
    this.isEditModalOpen = true;
  }

  hideEditModal(question) {
    this.isEditModalOpen = false;

    this.question = question;
  }
}
