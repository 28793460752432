import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { CommentCreateDto } from './models';
import { AnswerDto } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  apiName = 'ZenBoss';

  commentAnswer = (input: CommentCreateDto) =>
    this.restService.request<any, AnswerDto>({
      method: 'POST',
      url: '/api/zen-boss/comments',
      body: input,
    },
    { apiName: this.apiName });
  constructor(private restService: RestService) {}
}
