import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZenBossBaseControlComponent } from './zen-boss-base-control/zen-boss-base-control.component';
import {AgencyServiceProxy, UserServiceProxy} from '@proxy/shared';
import { ZenBossBaseListControlComponent } from './zen-boss-base-list-control/zen-boss-base-list-control.component';
import { ZenBossBaseSaveControlComponent } from './zen-boss-base-save-control/zen-boss-base-save-control.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import {PollCardComponent} from "./components/poll-questions/poll-card/poll-card.component";
import {StatusBadgeComponent} from "./components/poll-questions/status-badge/status-badge.component";
import {PollVoteUpDownComponent} from "./components/poll-questions/poll-vote-up-down/poll-vote-up-down.component";
import {CoreModule, LocalizationModule} from "@abp/ng.core";
import {BaseThemeSharedModule} from "@abp/ng.theme.shared";
import {BannerDisplayComponent} from "./components/banner-display/banner-display.component";


@NgModule({
  declarations: [
    ZenBossBaseControlComponent,
    ZenBossBaseListControlComponent,
    ZenBossBaseSaveControlComponent,
    ProfileCardComponent,
    PollCardComponent,
    StatusBadgeComponent,
    PollVoteUpDownComponent,
    BannerDisplayComponent
  ],
  exports: [
    ProfileCardComponent,
    PollCardComponent,
    StatusBadgeComponent,
    PollVoteUpDownComponent,
    BannerDisplayComponent
  ],
  imports: [
    CommonModule,
    CKEditorModule,
    LocalizationModule,
    CoreModule,
    BaseThemeSharedModule,
  ],
  providers: [
    UserServiceProxy,
    AgencyServiceProxy
  ]
})
export class SharedModule { }
