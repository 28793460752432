import { ABP, eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { Abp } from '@volo/abp.ng.account/public/proxy/lib/proxy/volo';
import { eZenBossRouteNames } from '../enums/route-names';
import {eThemeSharedRouteNames} from "@abp/ng.theme.shared";

export const ZEN_BOSS_ROUTE_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: configureRoutes,
    deps: [RoutesService],
    multi: true,
  },
];

export function configureRoutes(routes: RoutesService) {
  let account_route = {
    path: '/compnet',
        name: 'CompNet',
        layout: eLayoutType.application,
        invisible: true
  } as ABP.Route;

  return () => {
    routes.add([
      {
        path: '/',
        name:"Home",
        iconClass: 'fa fa-solid fa-message-question',
        order: 1,
        layout: eLayoutType.application,
      },
      {
        path: '/polls',
        name:"Polls",
        iconClass: 'fa fa-solid fa-square-poll-horizontal',
        requiredPolicy: 'ZenBoss.PollQuestions.Ask',
        order: 2,
        layout: eLayoutType.application,
      },
      {
        path: '/banners',
        name:"Banners",
        iconClass: 'fa fa-solid fa-square-poll-horizontal',
        parentName: eThemeSharedRouteNames.Administration,
        requiredPolicy: 'ZenBoss.Banners.Manage',
        order: 3,
        layout: eLayoutType.application,
      },
      account_route,
      {
        path: '/my-profile',
        name: "My profile",
        iconClass: 'fa fa-solid fa-messages-question',
        order: 1,
        layout: eLayoutType.application,
        requiredPolicy: 'ZenBoss.Questions',
        invisible: true,
      },
      {
        path: '/answer-request',
        name: "Answer Request",
        iconClass: 'fa fa-solid fa-messages-question',
        order: 1,
        layout: eLayoutType.empty,
        invisible: true,
      }
    ]);
  };
}
