<ng-container class="poll-container" *ngIf="poll">
  <div class="poll-container-row">
    <div class="poll-container__header">
      <div class="poll-container__header__logo">
        <div class="poll-container__header__logo__image">
          <img class="" src="../assets/logos/compnet-logo-dark.png" />
        </div>
      </div>
    </div>
    <div *ngIf="!hasBeenAnswered && !isPollClosed && !answeredInPreviousSession" class="poll-container__form">
      <div class="poll-container__form__question">
        <div class="poll-container__form__question__title">{{ poll.title }}</div>
        <div class="poll-container__form__question__body" [innerHTML]="poll.questionBody"></div>
      </div>
      <div class="poll-container__form__answers">
        <div class="poll-container__form__answers__text">
          {{ 'ZenBoss::PollQuestion.SelectAnswer' | abpLocalization }}
        </div>
        <ng-container *ngFor="let answerOption of poll.answerOptions">
          <div class="poll-container__form__answers__option">
            <div class="poll-container__form__answers__option__control">
              <input
                type="radio"
                class="radio-input form-check-input"
                [value]="answerOption.id"
                [checked]="initialPollAnswerId == answerOption.id"
                name="pollAnswerOption"
                (change)="onPollAnswerChange($event)"
              />
            </div>
            <div class="poll-container__form__answers__option__text">
              {{ answerOption.answer }}
            </div>
          </div>
        </ng-container>
      </div>
      <div class="poll-container__form__submit">
        <button
          id="submit"
          class="btn btn-primary btn-lg"
          type="button"
          style="display: flex; align-items: center !important"
          (click)="submitPollAnswerForm()"
        >
          <span class="">{{ 'ZenBoss::Input:Submit' | abpLocalization }}</span>
        </button>
      </div>
    </div>
    <div *ngIf="hasBeenAnswered" class="poll-container__form">
      <div class="poll-container__form__question">
        <div class="poll-container__form__question__title">{{ 'ZenBoss::PollQuestion.ThanksForParticipatingInPoll' | abpLocalization }}</div>
        <div class="poll-container__form__question__body">{{ 'ZenBoss::PollQuestion.AppreciatedInput' | abpLocalization }}
        </div>
        <div class="poll-container__form__question__thanks">{{ 'ZenBoss::PollQuestion.AppreciatedTime' | abpLocalization }}</div>
        <div class="poll-container__form__question__team">{{ 'ZenBoss::CompNetTeam' | abpLocalization }}</div>
        <div class="poll-container__form__question__action">{{ 'ZenBoss::Input:CloseWindow' | abpLocalization }}</div>
      </div>
    </div>
    <div *ngIf="isPollClosed" class="poll-container__form">
      <div class="poll-container__form__question">
        <div class="poll-container__form__question__expired-icon">
          <i class="fa fa-solid fa-warning"></i>
        </div>
        <div class="poll-container__form__question__title">
          {{ 'ZenBoss::PollQuestion.ClosedPoll' | abpLocalization }}
        </div>
        <div class="poll-container__form__question__body">
          No more answers will be accepted on this poll. <br />Keep a look out for the next one!
        </div>
        <div class="poll-container__form__question__thanks">{{ 'ZenBoss::PollQuestion.AppreciatedTime' | abpLocalization }}</div>
        <div class="poll-container__form__question__team">{{ 'ZenBoss::CompNetTeam' | abpLocalization }}</div>
      </div>
    </div>
    <div *ngIf="answeredInPreviousSession && !isPollClosed" class="poll-container__form">
      <div class="poll-container__form__question">
        <div class="poll-container__form__question__title">
          {{ poll.title }}
        </div>
        <div class="poll-container__form__question__body">
          {{ 'ZenBoss::PollQuestion.AnsweredPoll' | abpLocalization }}
        </div>
        <div class="poll-container__form__question__answer-option">
          <div class="poll-container__form__question__answer-option__text">{{ 'ZenBoss::PollQuestion.YourSelectedAnswer' | abpLocalization }}</div>
          <div class="poll-container__form__question__answer-option__value">{{previouslyAnsweredOption}}</div>
        </div>
        <div class="poll-container__form__question__thanks">{{ 'ZenBoss::PollQuestion.ThanksForParticipating' | abpLocalization }}</div>
        <div class="poll-container__form__question__team">{{ 'ZenBoss::CompNetTeam' | abpLocalization }}</div>
      </div>
    </div>
    <div class="poll-container__footer"></div>
  </div>
</ng-container>
