<div class="status-badge" [ngClass]="isDraft ? 'draft': ''">
  <div class="status-badge__image">
    <img class="img" src="../assets/logo-small.png" alt="" />
  </div>
  <div class="status-badge__title">
    <div class="status-badge__title__text">{{ 'ZenBoss::CompNetPoll' | abpLocalization }}</div>
  </div>
  <div class="status-badge__duration">
    <div class="status-badge__duration__text" [ngClass]="isOpen ? 'open': ''">
      {{ elapsedText }}
    </div>
  </div>
</div>
