import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ListService, PagedResultDto, TrackByService, ABP } from '@abp/ng.core';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  AgencyDto,
  AgencyServiceProxy,
  PollQuestionDto,
  PollQuestionServiceProxy,
  UserServiceProxy
} from '@proxy/shared';
import { AccountService } from '@volo/abp.ng.account/public';
import { ConfigStateService } from '@abp/ng.core';
import { DomSanitizer } from '@angular/platform-browser';
import { ZenBossBaseListControlComponent } from '../shared/zen-boss-base-list-control/zen-boss-base-list-control.component';

@Component({
  selector: 'lib-poll-questions',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './poll-questions.component.html',
  styleUrls: ['./poll-questions.component.css'],
  providers: [ListService, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
})
export class PollQuestionsComponent extends ZenBossBaseListControlComponent implements OnInit {
  data: PagedResultDto<PollQuestionDto> = {
    items: [],
    totalCount: 0,
  };

  agencies: AgencyDto[];

  constructor(
    public readonly userService: UserServiceProxy,
    public readonly configState: ConfigStateService,
    public readonly accountService: AccountService,
    public readonly sanitizer: DomSanitizer,
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly router: Router,
    public readonly service: PollQuestionServiceProxy,
    public readonly agencyService: AgencyServiceProxy,
  ) {
    super(userService, configState, accountService, sanitizer, list, router);
  }

  ngOnInit(): void {
    this.list.maxResultCount = this.pageSize;
    const getData = (query: ABP.PageQueryParams) => this.service.pollQuestionsGET(
      query.filter,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      query.sorting,
      query.skipCount,
      this.list.maxResultCount);

    const setData = (list: PagedResultDto<PollQuestionDto>) => (this.data = list);

    this.list
      .hookToQuery(getData)
      .pipe(finalize(() => this.scrollToTop()))
      .subscribe(setData);

    this.agencyService.getAll().subscribe(result => this.agencies = result);
  }

  public viewPoll(item): void {
    this.view(item.id, 'polls/view-poll')
  }

  public publish(item): void{
    this.service.publish(item.id).subscribe();
  }

  public get hasMoreItems(): boolean {
    return this.data.items.length < this.data.totalCount;
  }
}
