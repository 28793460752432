import type { VoteCreateDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { QuestionDto } from '../models';
import { AnswerDto } from '../answers';
import { PollQuestionDto } from '@proxy/shared';

@Injectable({
  providedIn: 'root',
})
export class VoteService {
  apiName = 'ZenBoss';

  castQuestionVote = (input: VoteCreateDto) =>
    this.restService.request<any, QuestionDto>({
      method: 'POST',
      url: '/api/zen-boss/votes/question',
      body: input,
    },
    { apiName: this.apiName });

    castQuestionAnswerVote = (input: VoteCreateDto) =>
    this.restService.request<any, AnswerDto>({
      method: 'POST',
      url: '/api/zen-boss/votes/answer',
      body: input,
    },
    { apiName: this.apiName });

    castPollQuestionVote = (input: VoteCreateDto) =>
    this.restService.request<any, PollQuestionDto>({
      method: 'POST',
      url: '/api/zen-boss/votes/poll-question',
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
