import { PageModule } from '@abp/ng.components/page';
import { CoreModule, LazyModuleFactory } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { ModuleWithProviders, NgModule, NgModuleFactory } from '@angular/core';

import { MyQuestionsComponent } from './my-questions.component';
import { MyQuestionsRoutingModule } from './my-questions-routing.module';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyProfileCardComponent } from './my-profile-card/my-profile-card.component';
import { AccountPublicModule } from '@volo/abp.ng.account/public';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxValidateCoreModule } from '@ngx-validate/core';

import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PersonalSettingsComponent } from './personal-settings/personal-settings.component';
import { TwoFactorTabComponent } from './two-factor-tab/two-factor-tab.component';
import { QuestionsModule } from '../questions/questions.module';
import { QuestionCardComponent } from '../questions/components/question-card/question-card.component';
import { UserServiceProxy } from '@proxy/shared';

@NgModule({
  declarations: [
    MyQuestionsComponent, 
    MyProfileComponent, 
    MyProfileCardComponent, 
    ProfilePictureComponent, 
    ChangePasswordComponent,
    PersonalSettingsComponent,
    TwoFactorTabComponent],
  imports: [
    CoreModule, 
    CommercialUiModule,       
    ThemeSharedModule, 
    MyQuestionsRoutingModule, 
    PageModule,
    CoreModule, 
    ThemeSharedModule, 
    PageModule, 
    FormsModule, 
    ReactiveFormsModule,    
    CKEditorModule,    
    ReactiveFormsModule,    
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    NgbTooltipModule,
    AccountPublicConfigModule,
    AccountPublicModule,
    QuestionsModule,
    ],
  exports: [
    MyQuestionsComponent,
  ],
  providers: [
    UserServiceProxy, 
  ]
})
export class MyQuestionsModule { 
  static forChild(): ModuleWithProviders<MyQuestionsModule> {
    return {
      ngModule: MyQuestionsModule,
      providers: [],
    };
  }

  static forLazy(): NgModuleFactory<MyQuestionsModule> {
    return new LazyModuleFactory(MyQuestionsModule.forChild());
  }
}
