<div class="mt-4">
  <div class="d-flex justify-content-between align-items-center">
    <h1 style="font-size: 18px" class="m-0 mb-4">{{ 'ZenBoss::PollQuestion.All' | abpLocalization }}</h1>
    <div class="mb-4">
      <button
        id="create"
        class="btn btn-primary btn-lg"
        type="button"
        routerLink="/polls/create-poll"
        routerLinkActive="active"
        style="display: flex; align-items: center !important"
      >
        <i class="fa fa-solid fa-square-poll-horizontal me-2"></i>
        <span class="">{{ 'ZenBoss::PollQuestion.Create' | abpLocalization }}</span>
      </button>
    </div>
  </div>

  <div class="row mb-4">
    <abp-entity-filter [list]="list"></abp-entity-filter>
  </div>
  <ng-template #loading>
    <p class="placeholder-glow">
      <span class="placeholder rounded col-6"></span>
      <br/>
      <span class="placeholder rounded w-75"></span>
      <br/>
      <span class="placeholder rounded" style="width: 25%"></span>
    </p>
  </ng-template>
  <ng-container *ngIf="data.items; else loading">
    <div
      class="card poll"
      *ngFor="let item of data.items; let i = index"
      [attr.data-index]="i"
    >
      <lib-poll-card [pollQuestion]="item" [agencies]="agencies" [isList]="true"></lib-poll-card>
    </div>
  </ng-container>
  <div class="row">
    <div class="col col-md-12">
      <div class="d-flex justify-content-center mb-0 mb-lg-0 mt-lg-1">
        <ng-template #nomore>
          <p class="m-0 p-0">{{ 'ZenBoss::List:End' | abpLocalization }}</p>
        </ng-template>
        <ng-container *ngIf="hasMoreItems; else nomore">
          <button
            class="btn btn-primary btn-lg"
            type="button"
            (click)="showMore()"
            routerLinkActive="active"
          >
            <i class="fa fa-solid fa-loader me-2"></i>
            <span class="">{{ 'ZenBoss::List:More.Polls' | abpLocalization }}</span>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
