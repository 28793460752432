<form validateOnSubmit *ngIf="form" [formGroup]="form">
  <div class="mb-3">
    <label for="name" class="form-label">First name</label
    ><input type="text" id="name" class="form-control" formControlName="name" readonly />
  </div>

  <div class="mb-3">
    <label for="surname" class="form-label">Last name</label
    ><input type="text" id="surname" class="form-control" formControlName="surname" readonly />
  </div>

  <div class="mb-3">
    <label for="email-address" class="form-label">Email</label>
    <div class="input-group" validationTarget validationStyle>
      <input type="text" id="email-address" class="form-control" formControlName="email" readonly />
      <div
        *ngIf="{
          edited: form.value.email !== storedProfile?.email,
          confirmed: storedProfile?.emailConfirmed
        } as data"
        class="input-group-append"
      >
        <abp-personal-settings-verify-button
          *ngIf="(showEmailVerificationBtn$ | async) || data.edited || data.confirmed"
          [verified]="data.confirmed"
          [edited]="data.edited"
          (btnClick)="sendEmailVerificationToken()"
        ></abp-personal-settings-verify-button>
      </div>
    </div>
  </div>
  <div class="mb-3">
    <label for="phone-number" class="form-label">Phone number</label>
    <div class="input-group mb-3">
      <input
        type="text"
        id="phone-number"
        class="form-control"
        formControlName="phoneNumber"
        readonly
      />
      <div
        class="input-group-append"
        *ngIf="storedProfile?.phoneNumber && isEnablePhoneNumberConfirmation"
      >
        <abp-personal-settings-verify-button
          [verified]="storedProfile?.phoneNumberConfirmed"
          [edited]="form.value.phoneNumber !== storedProfile?.phoneNumber"
          (btnClick)="initPhoneNumberConfirmation()"
        ></abp-personal-settings-verify-button>
      </div>
    </div>
  </div>
  
</form>
<div class="row">
  <div class="col-12">
    <button class="btn btn-outline-primary" (click)="showForm()">
      <i class="fa-solid fa-pen me-2"></i> Edit
    </button>
  </div>
</div>
<abp-modal [(visible)]="modalUpdateVisible" [busy]="modalUpdateBusy">
  <ng-template #abpHeader>
    <lib-profile-picture></lib-profile-picture>
  </ng-template>
  <ng-template #abpBody>
    <h5 style="color: #362cad">Edit profile information</h5>
    <form *ngIf="form" [formGroup]="form">
      <div class="mb-3">
        <label for="name" class="form-label">First name</label
        ><input type="text" id="name" class="form-control" formControlName="name" />
      </div>

      <div class="mb-3">
        <label for="surname" class="form-label">Last name</label
        ><input type="text" id="surname" class="form-control" formControlName="surname" />
      </div>

      <div class="mb-3">
        <label for="email-address" class="form-label">Email</label>
        <div class="input-group" validationTarget validationStyle>
          <input type="text" id="email-address" class="form-control" formControlName="email" />
        </div>
      </div>
      <div class="mb-3">
        <label for="phone-number" class="form-label">Phone number</label>
        <div class="input-group mb-3">
          <input type="text" id="phone-number" class="form-control" formControlName="phoneNumber" />
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #abpFooter>
    <abp-button
      iconClass="fa fa-check"
      buttonClass="btn btn-outline-primary"
      buttonType="submit"
      [disabled]="form?.invalid"
      (click)="submit()"
      abpClose
      >Save</abp-button
    >
    <button type="button" class="btn btn-secondary me-2" abpClose>Cancel</button>
  </ng-template>
</abp-modal>

<abp-modal [(visible)]="modalVisible" [busy]="modalBusy" [options]="{ size: 'sm' }">
  <ng-template #abpHeader>
    <h5>{{ 'AbpAccount::Verify' | abpLocalization }}</h5>
  </ng-template>
  <ng-template #abpBody>
    <form (ngSubmit)="confirmPhoneNumber()">
      <div class="mt-2">
        <p>
          {{ 'AbpAccount::ConfirmationTokenSentMessage' | abpLocalization: form.value.phoneNumber }}
        </p>
        <div class="mb-3">
          <label class="form-label" for="confirm-phone-number">{{
            'AbpAccount::PhoneConfirmationToken' | abpLocalization
          }}</label>
          <input
            [(ngModel)]="token"
            id="confirm-phone-number"
            name="confirm-phone-number"
            class="form-control"
            type="text"
            autofocus
          />
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #abpFooter>
    <button abpClose type="button" class="btn btn-secondary">
      Cancel
    </button>
    <abp-button type="abp-button" iconClass="fa fa-check" (click)="confirmPhoneNumber()">
      Save
    </abp-button>
  </ng-template>
</abp-modal>
