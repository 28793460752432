import { NgModule, NgModuleFactory, ModuleWithProviders } from '@angular/core';
import { CoreModule, LazyModuleFactory } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { QuestionsComponent } from './questions.component';
import { QuestionsRoutingModule } from './questions-routing.module';
import { PageModule } from '@abp/ng.components/page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { AskQuestionComponent } from './ask-question/ask-question.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TagInputModule } from 'ngx-chips';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { ViewQuestionComponent } from './view-question/view-question.component';
import { HowToAskAQuestionComponent } from './components/how-to-ask-a-question/how-to-ask-a-question.component';
import { QuestionCardComponent } from './components/question-card/question-card.component';
import { QuestionCardDetailedComponent } from './components/question-card-detailed/question-card-detailed.component';
import { AnswersComponent } from './answers/answers.component';
import { PostAnswerComponent } from './answers/post-answer/post-answer.component';
import { VoteUpDownComponent } from './components/vote-up-down/vote-up-down.component';
import { ZenBossSortPipe } from '../sort.pipe';
import { ViewAnswerComponent } from './answers/view-answer/view-answer.component';
import { CommentsComponent } from './answers/comments/comments.component';
import { PostCommentComponent } from './answers/comments/post-comment/post-comment.component';
import { ViewCommentComponent } from './answers/comments/view-comment/view-comment.component';
import { ZenBossTagInputComponent } from './components/tag-input/tag-input.component';
import {
  AgencyServiceProxy,
  API_BASE_URL,
  BannerUploadServiceProxy,
  QuestionServiceProxy,
  UserServiceProxy
} from '@proxy/shared';
import { environment } from 'projects/dev-app/src/environments/environment';
import { SharedModule } from '../shared/shared.module';


TagInputModule.withDefaults({
  tagInput: {
    placeholder: 'Add tag',
  },
});

@NgModule({
  declarations: [
    QuestionsComponent,
    AskQuestionComponent,
    ViewQuestionComponent,
    HowToAskAQuestionComponent,
    QuestionCardComponent,
    QuestionCardDetailedComponent,
    AnswersComponent,
    PostAnswerComponent,
    VoteUpDownComponent,
    ZenBossSortPipe,
    ViewAnswerComponent,
    CommentsComponent,
    PostCommentComponent,
    ViewCommentComponent,
    ZenBossTagInputComponent,
  ],
  imports: [
    CoreModule,
    ThemeSharedModule,
    QuestionsRoutingModule,
    PageModule,
    FormsModule,
    ReactiveFormsModule,
    CommercialUiModule,
    CKEditorModule,
    TagInputModule,
    ReactiveFormsModule,
    NgxValidateCoreModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    SharedModule
  ],
  exports: [
    QuestionsComponent,
    QuestionCardComponent,
    QuestionCardDetailedComponent,
    AnswersComponent,
    AskQuestionComponent,
    PostAnswerComponent,
  ],
  providers: [
    UserServiceProxy,
    AgencyServiceProxy,
    BannerUploadServiceProxy,
    QuestionServiceProxy,
    {
      provide: API_BASE_URL,
      useValue: environment.apis.ZenBoss.url,
    },
  ],
})
export class QuestionsModule {
  static forChild(): ModuleWithProviders<QuestionsModule> {
    return {
      ngModule: QuestionsModule,
      providers: [],
    };
  }

  static forLazy(): NgModuleFactory<QuestionsModule> {
    return new LazyModuleFactory(QuestionsModule.forChild());
  }
}

export function loadQuestionsModuleAsChild() {
  return Promise.resolve(QuestionsModule);
}
