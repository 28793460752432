

import { AuditedEntityDto } from '@abp/ng.core';
import { QuestionStatus } from '@proxy/questions';
import { ZenBossTagDto } from './tag-dto';


export enum InsertEdit {
  Insert = 0,
  Edit = 1,
}

export class QuestionDto extends AuditedEntityDto<string> {
  title: string;
  description: string;
  tags: ZenBossTagDto[];
  
  lastModificationTime?: string;
  lastModifierId?: string;
  creationTime: string;
  creatorId?: string;
  ıd: string;

  constructor(initialValues: Partial<QuestionDto> = {}) {
    super(initialValues);
  }
}

export class QuestionUpdateDto {
  title: string;
  questionBody: string;
  status?: QuestionStatus;
  closeReason?: string;
  closedDate?: string;
  closedBy?: string;
  closedByName?: string;
  views?:number;
}
