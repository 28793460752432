import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@zen-boss').then(m => m.QuestionsModule.forLazy()),
  },
  {
    path: '',
    loadChildren: () =>
      import('@zen-boss').then(m => m.ZenBossModule.forLazy()),
  },
  {
    path:'compnet',
    loadChildren: () =>
      import('@zen-boss').then(m=>m.CompnetModule.forLazy()),
  },
  {
    path:'my-profile',
    loadChildren: () =>
      import('@zen-boss').then(m=>m.MyQuestionsModule.forLazy()),
  },
  {
    path:'polls',
    loadChildren: () =>
      import('@zen-boss').then(m=>m.PollQuestionsModule.forLazy()),
  },
  {
    path:'answer-request',
    loadChildren: () =>
      import('@zen-boss').then(m=>m.AnswerRequestModule.forLazy()),
  },
  {
    path:'banners',
    loadChildren: () =>
      import('@zen-boss').then(m=>m.BannersModule.forLazy()),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('@volo/abp.ng.account/public').then(m => m.AccountPublicModule.forLazy()),
  },
  {
    path: 'identity',
    loadChildren: () => import('@volo/abp.ng.identity').then(m => m.IdentityModule.forLazy()),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
