<div class="row">
  <div class="col col-md-12">
    <tag-input theme='zen-boss-theme'></tag-input>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <h4 class="mx-2 my-2 my-md-3" style="color: #362cad; font-size: 18px">
      Filter from our favourite tags
    </h4>
  </div>

  <div class="row">
    <div class="col col-md-12">
      <div class="mx-2 mb-2 mb-md-4">Element tags</div>
      <div class="d-flex justify-content-start flex-wrap px-2">
        <div *ngFor="let item of ELEMENT_TAGS; let i = index" [attr.data-index]="i">
          <button type="button" class="btn btn-outline-primary btn-elements me-2 mb-2 d-flex">
            <i class="fa-solid fa-circle my-auto me-2" style="font-size: 8px; color: #acf096"></i>
            {{ item.display }}
            <i class="fa-solid fa-plus my-auto ms-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col col-md-12">
      <div class="mx-2 my-2 my-md-4">Sector tags</div>
      <div class="d-flex justify-content-start flex-wrap px-2">
        <div *ngFor="let item of SECTOR_TAGS; let i = index" [attr.data-index]="i">
          <button type="button" class="btn btn-outline-primary btn-sector me-2 mb-2 d-flex">
            <i class="fa-solid fa-circle my-auto me-2" style="font-size: 8px; color: #9d6cff"></i>
            {{ item.display }}
            <i class="fa-solid fa-plus minus my-auto ms-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col col-md-12">
      <div class="mx-2 my-2 my-md-4">Legislator tags</div>
      <div class="d-flex justify-content-start flex-wrap px-2">
        <div *ngFor="let item of LEGISLATOR_TAGS; let i = index" [attr.data-index]="i">
          <button
            type="button"
            class="btn btn-outline-primary btn-legislator me-2 mb-2 d-flex"
            style="border-color: #102a43"
          >
            <i class="fa-solid fa-circle my-auto me-2" style="font-size: 8px; color: #02dac6"></i>
            {{ item.display }}
            <i class="fa-solid fa-plus my-auto ms-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col col-md-12 mb-4">
      <div class="mx-2 my-2 my-md-4">Council tags</div>
      <div class="d-flex justify-content-start flex-wrap px-2">
        <div *ngFor="let item of COUNCIL_TAGS; let i = index" [attr.data-index]="i">
          <button
            type="button"
            class="btn btn-outline-primary btn-council me-2 mb-2 d-flex"
            style="border-color: #102a43"
          >
            <i class="fa-solid fa-circle my-auto me-2" style="font-size: 8px; color: #01c2ff"></i>
            {{ item.display }}
            <i class="fa-solid fa-plus my-auto ms-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
