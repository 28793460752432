import { Component, OnInit } from '@angular/core';
import { eThemeLeptonComponents } from '@volo/abp.ng.theme.lepton';
import { ReplaceableComponentsService } from '@abp/ng.core';
import {
  CurrentUserComponent,
  ZenBossFooterComponent,
  NavItemsComponent,
  CurrentUserImageComponent,
} from '@zen-boss';
import { NavItemsService } from '@abp/ng.theme.shared';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
  `,
})
export class AppComponent implements OnInit {
  constructor(
    private replaceableComponents: ReplaceableComponentsService,
    public readonly navItems: NavItemsService) {}
  ngOnInit(): void {
    this.replaceableComponents.add({
      component: ZenBossFooterComponent,
      key: eThemeLeptonComponents.ApplicationLayoutFooter,
    });
    this.replaceableComponents.add({
      component: NavItemsComponent,
      key: eThemeLeptonComponents.NavItems,
    });
    this.replaceableComponents.add({
      component: CurrentUserImageComponent,
      key: eThemeLeptonComponents.CurrentUserImage,
    });
    this.navItems.patchItem(eThemeLeptonComponents.CurrentUser, {
      component: CurrentUserComponent,
    });
  }
}
