import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {ABP, ListService, PagedResultDto, TrackByService} from '@abp/ng.core';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import {
  AgencyDto, AgencyServiceProxy,
  QuestionServiceProxy,
  UnifiedResultDto,
  UserServiceProxy,
} from '@proxy/shared';
import { AccountService } from '@volo/abp.ng.account/public';
import { ConfigStateService } from '@abp/ng.core';
import { DomSanitizer } from '@angular/platform-browser';
import { ZenBossBaseListControlComponent } from '../shared/zen-boss-base-list-control/zen-boss-base-list-control.component';
import {finalize} from "rxjs/operators";

@Component({
  selector: 'lib-questions',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  providers: [ListService, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
})
export class QuestionsComponent extends ZenBossBaseListControlComponent implements OnInit {

  data: PagedResultDto<UnifiedResultDto> = {
    items: [],
    totalCount: 0,
  };

  agencies: AgencyDto[];

  constructor(
    public readonly userService: UserServiceProxy,
    public readonly agencyService: AgencyServiceProxy,
    public readonly configState: ConfigStateService,
    public readonly accountService: AccountService,
    public readonly sanitizer: DomSanitizer,
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly questionService: QuestionServiceProxy,
    public readonly service: QuestionServiceProxy,
    public readonly router: Router
  ) {
    super(userService, configState, accountService, sanitizer, list, router);
  }

  ngOnInit(): void {
    this.list.maxResultCount = this.pageSize;
    const getData = (query: ABP.PageQueryParams) => this.questionService.unified(
      query.filter,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      query.sorting,
      query.skipCount,
      this.list.maxResultCount);

    const setData = (list: PagedResultDto<UnifiedResultDto>) => (this.data = list);

    this.list
      .hookToQuery(getData)
      .pipe(finalize(() => this.scrollToTop()))
      .subscribe(setData);

    this.agencyService.getAll().subscribe(result => this.agencies = result);

  }

  public viewQuestion(item): void {
    this.view(item.id, 'view-question');
  }

  public viewPoll(item): void {
    this.view(item.id, 'polls/view-poll')
  }

  public get hasMoreItems(): boolean {
    return this.data.items.length < this.data.totalCount;
  }
}
