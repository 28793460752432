import { ModuleWithProviders, NgModule } from '@angular/core';
import { ZEN_BOSS_ROUTE_PROVIDERS } from './providers/route.provider';

@NgModule()
export class ZenBossConfigModule {
  static forRoot(): ModuleWithProviders<ZenBossConfigModule> {
    return {
      ngModule: ZenBossConfigModule,
      providers: [
        ZEN_BOSS_ROUTE_PROVIDERS,
      ],
    };
  }
}
