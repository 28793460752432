import { Component, OnInit, Input } from '@angular/core';
import {QuestionDto} from "@proxy/shared";

@Component({
  selector: 'lib-question-card',
  templateUrl: './question-card.component.html',
  styleUrls: ['./question-card.component.scss']
})

export class QuestionCardComponent implements OnInit {
  @Input() question: QuestionDto;
  constructor(
  ) { }

  ngOnInit(): void {

  }
}
