import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ConfigStateService, ListService, LocalizationService} from '@abp/ng.core';
import {FormBuilder} from '@angular/forms';
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDatepicker,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import {ToasterService} from '@abp/ng.theme.shared';
import {finalize, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {
  ZenBossBaseSaveControlComponent
} from '../../shared/zen-boss-base-save-control/zen-boss-base-save-control.component';
import {AccountService} from '@volo/abp.ng.account/public';
import {DomSanitizer} from '@angular/platform-browser';
import {
  AnswerOptionDto,
  PollQuestionDto,
  PollQuestionServiceProxy,
  UserServiceProxy,
} from '@proxy/shared';
import {InsertEdit} from '../../questions/models/question-dto';
import {PollQuestionService} from '@proxy/questions';
import * as moment from 'moment';
import {Observable} from "rxjs";

@Component({
  selector: 'lib-create-poll',
  templateUrl: './create-poll.component.html',
  styleUrls: ['./create-poll.component.scss'],
  providers: [ListService, {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}],
})
export class CreatePollComponent extends ZenBossBaseSaveControlComponent implements OnInit {
  @ViewChild('answerOption') answerOption: ElementRef;
  @ViewChild('d') private dp: NgbDatepicker;
  selectedItem: any;
  pageTitle: string = '';
  newAnswerOption: string = '';
  pollEndDate: NgbDateStruct = null;
  pollMinDate: NgbDateStruct = null;

  pollQuestionId: string = null;

  constructor(
    public readonly userService: UserServiceProxy,
    public readonly configState: ConfigStateService,
    public readonly accountService: AccountService,
    public readonly sanitizer: DomSanitizer,
    public readonly list: ListService,
    public readonly router: Router,
    private route: ActivatedRoute,
    public readonly service: PollQuestionServiceProxy,
    public readonly pollQuestionService: PollQuestionService,
    public readonly fb: FormBuilder,
    public readonly toaster: ToasterService,
    private l: LocalizationService,
    private calendar: NgbCalendar
  ) {
    super(userService, configState, accountService, sanitizer, list, router, fb, toaster);
    this.editor.editorConfig = function (config) {
      config.height = '300px';
    };
  }

  ngOnInit(): void {
    this.initItem();
    this.route.params.subscribe(params => {
      this.pollQuestionId = params.id;
      this.mode = params.mode ?? InsertEdit.Insert
    });
    if (this.isInsert) {
      this.l.get('ZenBoss::PollQuestion.Create').subscribe(x => (this.pageTitle = x));
    } else {
      this.l.get('ZenBoss::PollQuestion.Edit').subscribe(x => (this.pageTitle = x));
      this.isFirstLoad = false;
      this.service.getForEdit(this.pollQuestionId).subscribe(result => {
        this.selectedItem = result
      })
    }
  }

  private initItem(): void {
    this.selectedItem.title = '';
    this.selectedItem.questionBody = '';
    this.selectedItem.answerOptions = [];
    this.pollMinDate = this.calendar.getToday();
    this.pollEndDate = this.calendar.getNext(this.calendar.getToday(), 'd', 2);
  }

  private okSave(publish: boolean = false): void {
    if (this.mode === InsertEdit.Insert) {
      if (publish) {
        this.toaster.success('Congrats, you have successfully posted a poll.', 'Poll Published');
        this.router.navigate(['/polls']);
      } else {
        this.toaster.success('Your poll has been saved as a draft.', 'Poll Created');
      }
    } else {
      if (publish) {
        this.toaster.success('Congrats, you have successfully posted a poll.', 'Poll Published');
        this.router.navigate(['/polls']);
      } else {
        this.toaster.success('Congrats, you have successfully updated your poll.', 'Poll Updated');
      }
    }
    this.saved.emit(this.selectedItem);
  }

  public addAnswerOption(option): void {
    if (this.isValidAnswerOption(option)) {
      let answerOption = new AnswerOptionDto();
      answerOption.answer = option;
      if (this.mode == InsertEdit.Insert) {
        this.isFirstLoad = this.selectedItem.answerOptions.length <= 0;
      }
      this.selectedItem.answerOptions.push(answerOption);
      this.newAnswerOption = '';
      this.answerOption.nativeElement.focus();
    }
  }

  public create(publish: boolean = false): void {
    let request: Observable<PollQuestionDto>;
    if (this.mode == InsertEdit.Insert) {
      request = this.pollQuestionService.create(this.selectedItem, publish);
    } else {
      request = this.pollQuestionService.update(this.pollQuestionId, this.selectedItem, publish);
    }

    request
      .pipe(
        finalize(() => this.okSave(publish)),
        tap(() => this.hideForm())
      )
      .subscribe(this.list.get);
  }

  public dateSelected($event: NgbDateStruct): void {
    this.selectedItem.closedDate = moment(this.toDateModelWithHoursMinutesSeconds($event));
  }

  public removeAnswerOption(index): void {
    this.selectedItem.answerOptions.splice(index, 1);
  }

  public isValidAnswerOption(answerOption?): boolean {
    if (answerOption) {
      return answerOption.length > 0 || this.isFirstLoad;
    }
  }

  public get hasEnoughValidAnswerOptions(): boolean {
    return this.selectedItem.answerOptions.length > 1 || this.isFirstLoad;
  }

  public get canAddMoreAnswerOptions(): boolean {
    return this.selectedItem.answerOptions.length < 3;
  }

  public get canSave(): boolean {
    return (
      this.hasEnoughValidAnswerOptions && this.selectedItem.title.length > 0 && !this.isFirstLoad
    );
  }
}
