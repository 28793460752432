<abp-page *ngIf="selectedItem" [title]="pageTitle" class="col-auto p-0 m-0">
  <div id="kohde-content-create-poll" class="row">
    <div class="col col-md-12">
      <div class="mb-3">
        <div class="form-group">
          <label class="">{{ 'ZenBoss::Input:Title' | abpLocalization }}</label><span> * </span>
          <input [(ngModel)]="selectedItem.title" type="text" class="form-control" autofocus />
        </div>
      </div>
      <div class="mb-3">
        <div class="form-group hide-border">
          <label for="poll-questionBody">{{ 'ZenBoss::Input:Description' | abpLocalization }}</label>
          <ckeditor
            [editor]="editor"
            [data]="selectedItem.questionBody"
            [(ngModel)]="selectedItem.questionBody"
            [config]="{
              toolbar: {
                items: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  '|',
                  'link',
                  '|',
                  'outdent',
                  'indent',
                  '|',
                  'bulletedList',
                  'numberedList',
                  '|',
                  'blockQuote',
                  '|',
                  'undo',
                  'redo'
                ],
                shouldNotGroupWhenFull: true
              }
            }"
            class="form-control"
          >
          </ckeditor>
        </div>
      </div>
      <div class="mb-3">
        <div class="form-group hide-border ">
          <label class="">{{ 'ZenBoss::PollQuestion.AnswerOptions' | abpLocalization
            }}</label><span> * </span>


          <div class="form-control p-4" [ngClass]="hasEnoughValidAnswerOptions ? '' : 'is-invalid'">
            <div class="d-flex flex-fill flex-column ms-2 mb-2">
              <div *ngFor="let option of selectedItem.answerOptions; let i = index" [attr.data-index]="i">
                <div class="d-flex flex-fill justify-content-between my-2">
                  <div class="d-flex align-items-center w-100">
                    <input [(ngModel)]="option.answer" type="text" class="form-control create-poll"
                      [ngClass]="isValidAnswerOption(option.answer) ? 'is-valid' : 'is-invalid'" required />
                  </div>
                  <div class="d-flex align-items-center m-0 ms-2">
                    <button type="button" class="btn btn-secondary m-0 h-100" (click)="removeAnswerOption(i)">
                      <i class="fa-solid fa-minus-large"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="canAddMoreAnswerOptions" class="d-flex flex-fill justify-content-between">
              <div class="d-flex align-items-center w-100">
                <div class="d-flex flex-fill flex-column">
                  <input #answerOption [(ngModel)]="newAnswerOption" placeholder="Type your answer here..."
                    [ngClass]="isValidAnswerOption(newAnswerOption) ? 'is-valid' : ''" type="text" class="form-control"
                    autofocus required />

                </div>
              </div>
              <div class="d-flex align-items-center m-0 ms-2">
                <button type="button" class="btn btn-secondary m-0 h-100" (click)="addAnswerOption(newAnswerOption)">
                  <i class="fa-solid fa-plus-large"></i>
                </button>
              </div>
            </div>
            <div *ngIf="pollEndDate && pollMinDate" class="input-group row mt-3 ">
              <div class="col col-3 ms-2">
                <div class="d-flex justify-content-between">
                  <button class="btn btn-outline-secondary btn-outline-secondary-right" type="button">
                    <i class="fa-regular fa-calendar"></i>
                  </button>
                  <input class="form-control kohde-create-poll-date" placeholder="Poll end date" name="dp"
                    [(ngModel)]="pollEndDate" [startDate]="pollEndDate" ngbDatepicker (dateSelect)="dateSelected($event)" #d="ngbDatepicker" [minDate]="pollMinDate" readonly />
                  <button class="btn btn-outline-secondary btn-outline-secondary-left" (click)="d.toggle(); d.navigateTo(pollEndDate);" type="button">
                    <i class="fa-solid fa-chevron-down"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="invalid-feedback p-1 border-danger border-1 border px-2">
            <i class="fa-solid fa-triangle-exclamation"></i>
            <span class="ms-1">Must have at least two possible answers</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <lib-tag-input *ngIf="isInsert"></lib-tag-input> -->
  <div class="row">
    <div class="col col-md-12">
      <ng-container>
        <div class="d-flex justify-content-between">
          <div>
            <button type="button" class="btn btn-lg btn-secondary" routerLink="/polls">
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
              {{ 'ZenBoss::Input:Back' | abpLocalization }}
            </button>
          </div>
          <div>
            <button *abpPermission="'ZenBoss.PollQuestions.Ask'" [disabled]="!canSave" class="btn btn-lg btn-secondary btn-save-as-draft"
              (click)="create()">
              <i class="fa fa-message-question mr-0"></i>
              {{ 'ZenBoss::PollQuestion.Post' | abpLocalization }}
            </button>
            <button *abpPermission="'ZenBoss.PollQuestions.Ask'" [disabled]="!canSave" class="btn btn-lg btn-primary"
              (click)="create(true)">
              <i class="fa fa-message-question mr-0"></i>
              {{ 'ZenBoss::PollQuestion.PostPublish' | abpLocalization }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</abp-page>
