import { Component, OnInit } from '@angular/core';
import { SECTOR_TAGS, ELEMENT_TAGS, COUNCIL_TAGS, LEGISLATOR_TAGS } from '../../models/tag-dto';
import { TagInputComponent } from 'ngx-chips';

@Component({
  selector: 'lib-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss']
})
export class ZenBossTagInputComponent implements OnInit {

  SECTOR_TAGS = SECTOR_TAGS;
  ELEMENT_TAGS = ELEMENT_TAGS;
  COUNCIL_TAGS = COUNCIL_TAGS;
  LEGISLATOR_TAGS = LEGISLATOR_TAGS;

  constructor() { }

  ngOnInit(): void {

  }

  addTag(tag) {

  }
}
