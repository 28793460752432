import type { EntityDto, PagedAndSortedResultRequestDto } from '@abp/ng.core';


export interface CommentDto extends EntityDto<string> {
  commentBody: string;
  commentDate: string;
  commentBy: string;
  questionId:string;
  answerId: string;
  duration: string;
}

export interface CommentCreateDto  {
  commentBody: string;
  commentDate: string;
  commentBy: string;
  questionId:string;
  answerId: string;
  duration: string;
  returnUrl:string;
}
