import { Component, OnInit, Input } from '@angular/core';
import { AnswerDto } from '@proxy/questions/answers';
import { VoteCreateDto, VoteService, voteValueOptions } from '@proxy/questions/votes';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'lib-view-answer',
  templateUrl: './view-answer.component.html',
  styleUrls: ['./view-answer.component.scss'],
})
export class ViewAnswerComponent implements OnInit {
  @Input() answer = {} as AnswerDto;
  @Input() isVisible = false as boolean;
  @Input() index = 0;

  isCommentsVisible = false;
  markers = ["kohde-marker-1", "kohde-marker-2", "kohde-marker-3", "kohde-marker-4"];

  btnText = 'Show comments' as string;

  constructor(public readonly service: VoteService) {}

  ngOnInit(): void {}

  castVote(vote) {
    vote.questionId = this.answer.questionId;
    vote.answerId = this.answer.id;
    const request = this.service.castQuestionAnswerVote(vote);

    request
      .pipe(
        finalize(() => this.ok()),
        tap(() => this.hide())
      )
      .subscribe(answer => (this.answer = answer));
  }

  private ok() {}

  hide() {}

  postComment(answer) {
    this.answer = answer;

    this.btnText = 'Hide comments';
    this.isCommentsVisible = true;

    this.toggle();
  }

  cancel(answer) {
    this.answer = answer;
    this.toggle();
  }

  toggle() {
    this.isVisible = !this.isVisible;
  }

  toggleComments() {
    this.isCommentsVisible = !this.isCommentsVisible;
    if (this.isCommentsVisible) {
      this.btnText = 'Hide comments';
    } else {
      this.btnText = 'Show comments';
    }
  }

  marker() {
    let value = ((this.index) % 4);
    let markerClass = this.markers[value]; 
    return markerClass;
  }
}
