<div class="banner-upload {{fileExists ? 'preview': ''}}">
  <div class="header">
    <div class="title">
      <div *ngIf="isList" class="text">{{ 'ZenBoss::Banners.PollListTitle' | abpLocalization }}</div>
      <div *ngIf="!isList" class="text">{{ 'ZenBoss::Banners.PollDetailTitle' | abpLocalization }}</div>
    </div>
    <div class="description">
      <div *ngIf="isList" class="text">{{ 'ZenBoss::Banners.PollListDescription' | abpLocalization }}</div>
      <div *ngIf="!isList" class="text">{{ 'ZenBoss::Banners.PollDetailDescription' | abpLocalization }}</div>
    </div>
  </div>
  <div class="button">
    <label class="btn btn-primary btn-md label">
      <input class="" type="file" style="display: none;" (change)="onFileChange($event)" />
      <i class="fa-thin fa-file me-2"></i>
      {{this.fileExists ? "Replace Banner" : "Upload"}}
    </label>
    <button
      *ngIf="this.fileExists"
      class="btn btn-danger btn-md"
      type="button"
      (click)="removeFile()"
      style="display: flex; align-items: center !important"
    >
      <i class="fa fa-thin fa-trash me-2"></i>
      <span class="">Remove Banner</span>
    </button>

  </div>
  <div class="note">
    <span>Note:</span> {{ 'ZenBoss::Banners.Size' | abpLocalization }}
  </div>
  <lib-banner-display *ngIf="fileExists" [isList]="isList"></lib-banner-display>
  <ng-container class="">
    <div *ngIf="messages.length > 0">
    <span *ngFor="let msg of messages" class="message">
        {{msg}}
      <br />
        </span>
    </div>
  </ng-container>
</div>
