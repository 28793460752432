import { NavItem, NavItemsService, UserMenuService } from '@abp/ng.theme.shared';
import { Component, Inject, Optional, TrackByFunction } from '@angular/core';
import { eThemeLeptonComponents } from '@volo/abp.ng.theme.lepton';
import { AuthService, ConfigStateService, NAVIGATE_TO_MANAGE_PROFILE } from '@abp/ng.core';
import {
  NAVIGATE_TO_MY_SECURITY_LOGS,
  OPEN_MY_LINK_USERS_MODAL,
} from '@volo/abp.commercial.ng.ui/config';
import { Params } from '@angular/router';
import { CurrentUserComponent } from '../current-user/current-user.component';

@Component({
  selector: 'abp-nav-items',
  templateUrl: './nav-items.component.html',
  styleUrls: ['./nav-items.component.scss'],
})
export class NavItemsComponent {
  trackByFn: TrackByFunction<NavItem> = (_, element) => element?.id;
  currentUser$ = this.configState.getOne$('currentUser');
  constructor(
    public readonly navItems: NavItemsService,
    @Inject(NAVIGATE_TO_MANAGE_PROFILE) public navigateToManageProfile,
    @Inject(NAVIGATE_TO_MY_SECURITY_LOGS) public navigateToMySecurityLogs,
    @Optional() @Inject(OPEN_MY_LINK_USERS_MODAL) public openMyLinkUsersModal,
    public readonly userMenu: UserMenuService,
    private authService: AuthService,
    private configState: ConfigStateService
  ) {
    navItems.removeItem(eThemeLeptonComponents.Languages);
    navItems.removeItem(eThemeLeptonComponents.FullScreen);
    navItems.patchItem(eThemeLeptonComponents.CurrentUser, {
      component: CurrentUserComponent,
    });
  }

  ngOnInit(): void {}

  navigateToLogin(queryParams?: Params) {
    this.authService.navigateToLogin(queryParams);
  }

  navigateToRegister(queryParams?: Params) {
    this.authService.navigateToLogin({ register: '1' });
  }
}
