import type { FullAuditedEntityDto, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { AnswerDto } from './answers';
import type { QuestionStatus } from './question-status.enum';
import { VoteDto } from './votes';

export interface GetQuestionsInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  title?: string;
  questionBody?: string;
  status?: QuestionStatus;
  closeReason?: string;
  closedDateMin?: string;
  closedDateMax?: string;
  closedBy?: string;
  openedDateMin?: string;
  openedDateMax?: string;
  openedBy?: string;
  openedByName?: string;
  closedByName?: string;
  views:number;
}

export interface QuestionCreateDto {
  title: string;
  questionBody: string;
}

export interface QuestionDto extends FullAuditedEntityDto<string> {
  title: string;
  questionBody: string;
  status: QuestionStatus;
  closeReason?: string;
  closedDate?: string;
  closedBy?: string;
  openedDate: string;
  openedBy: string;
  openedByName?: string;
  closedByName?: string;
  votes?: VoteDto[];
  views:number;
  duration:string;
  shortDesc:string;
  answers: AnswerDto[];
  voteCount:number;
  answerCount:number;
  voteDownCount: number;
  voteUpCount: number;
}

export interface QuestionUpdateDto {
  title: string;
  questionBody: string;
  status?: QuestionStatus;
  closeReason?: string;
  closedDate?: string;
  closedBy?: string;
  closedByName?: string;
  views?:number;
}
