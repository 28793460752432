import { Component, OnInit } from '@angular/core';
import { QuestionDto } from '../../../lib/proxy/questions';
import { ActivatedRoute, Router } from '@angular/router';
import { ListService } from '@abp/ng.core';
import { ConfirmationService } from '@abp/ng.theme.shared';
import { QuestionService } from '../../proxy/questions/question.service';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { finalize } from 'rxjs/operators';
import { ProfileDto } from '../models/profile-dto';

@Component({
  selector: 'lib-view-question',
  templateUrl: './view-question.component.html',
  styleUrls: ['./view-question.component.css'],
  providers: [ListService, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
})
export class ViewQuestionComponent implements OnInit {
  question = {} as QuestionDto;
  profile = {} as ProfileDto;
  public editor = ClassicEditor;
  constructor(
    public readonly list: ListService,
    private confirmation: ConfirmationService,
    public readonly service: QuestionService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.viewQuestion();
  }

  ngOnInit(): void {}

  viewQuestion() {
    this.question.id = this.route.snapshot.paramMap.get('id');
    this.service
      .get(this.question.id)
      .pipe(finalize(() => this.updateViewCount(this.question.id)))
      .subscribe(question => (this.question = question));
  }

  private updateViewCount(id) {
    this.scrollToTop();

    this.question.views = this.question.views + 1;
    this.service.edit(id, this.question).subscribe(question => (this.question = question));
    this.profile.duration = this.question.duration;
    this.profile.userId = this.question.openedBy;
  }

  postAnswer(question) {
    this.question = question;
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
