import {ConfigStateService} from '@abp/ng.core';
import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {
  BannerDto,
  BannerUploadServiceProxy,
  UserServiceProxy
} from '@proxy/shared';
import {AccountService} from '@volo/abp.ng.account/public';
import {ZenBossBaseControlComponent} from "../../shared/zen-boss-base-control/zen-boss-base-control.component";

@Component({
  selector: 'lib-banner-upload',
  templateUrl: './banner-upload.component.html',
  styleUrls: ['./banner-upload.component.scss'],
})
export class BannerUploadComponent extends ZenBossBaseControlComponent implements OnInit {
  @Input() isList: boolean = false;
  fileExists: boolean = false;
  banner: any = null;
  messages: string[] = [];
  maxFileSize: number = 5242880;

  constructor(
    public readonly userService: UserServiceProxy,
    public readonly configState: ConfigStateService,
    public readonly accountService: AccountService,
    public readonly bannerUploadService: BannerUploadServiceProxy,
    public readonly sanitizer: DomSanitizer,
  ) {
    super(userService, configState, accountService, sanitizer);
  }

  ngOnInit(): void {
    this.bannerUploadService.checkExists(this.isList).subscribe(result => this.fileExists = result);

    console.log('exists?', this.fileExists);
  }

  uploadFile(): void {
    this.readAndUploadFile(this.banner);
  }

  removeFile(): void {
    this.bannerUploadService.removeBanner(this.isList).subscribe(result => {
      this.messages.push(result.toString());
      this.fileExists = false;
    });
  }

  onFileChange(event) {
    this.banner = null;
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files[0].size < this.maxFileSize) {
        if (event.target.files[0].type == 'image/png') {
          this.banner = event.target.files[0];
          this.uploadFile()
        } else {
          this.messages.push("Banner: " + event.target.files[0].name + " is not in .png format.");
        }
      } else {
        this.messages.push("Banner: " + event.target.files[0].name + " is too large to upload.");
      }
    }
  }

  private readAndUploadFile(bannerFile: any) {
    let bannerForUpload = new BannerDto();
    bannerForUpload.fileName = bannerFile.name;
    bannerForUpload.fileSize = bannerFile.size.toString();
    bannerForUpload.fileType = bannerFile.type;

    let reader = new FileReader();
    reader.readAsDataURL(bannerFile);
    reader.onload = () => {
      bannerForUpload.fileAsBase64 = reader.result.toString();
      this.bannerUploadService.bannerUploadPOST(this.isList, bannerForUpload)
        .subscribe(result => {
          this.messages.push(result);
          this.fileExists = true;
        });
    }
  }
}
