import type { EntityDto, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { VoteDto } from '../votes';
import { AnswerStatus } from './answer-status.enum';
import { CommentDto } from './comments';


export interface AnswerDto extends EntityDto<string> {
  answerBody: string;
  status: AnswerStatus;
  answeredDate: string;
  answeredBy: string;
  statusChangedDate?: string;
  statusChangedBy?: string;
  answeredByName?: string;
  statusChangedByName?: string;
  closedByName?: string;
  views:number;
  questionId:string;
  votes: VoteDto[];
  comments: CommentDto[];
  voteCount: number;
  voteDownCount: number;
  voteUpCount: number;
  shortDesc:string;
}

export interface AnswerCreateDto  {
  answerBody: string;
  status: AnswerStatus;
  answeredDate: string;
  answeredBy: string;
  statusChangedDate?: string;
  statusChangedBy?: string;
  answeredByName?: string;
  statusChangedByName?: string;
  closedByName?: string;
  views:number;
  questionId:string;
  returnUrl?:string;
}
