import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { ConfigStateService } from '@abp/ng.core';
import { AccountService } from '@volo/abp.ng.account/public';
import { DomSanitizer } from '@angular/platform-browser';
import { UserServiceProxy } from '@proxy/shared';
import { ZenBossBaseControlComponent } from '../../zen-boss-base-control/zen-boss-base-control.component';

@Component({
  selector: 'lib-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent extends ZenBossBaseControlComponent implements OnInit, OnChanges {
  @Input() controlClass: string = 'default';
  constructor(
    public readonly userService: UserServiceProxy,
    public readonly configState: ConfigStateService,
    public readonly accountService: AccountService,
    public readonly sanitizer: DomSanitizer,
  ) {
    super(userService, configState, accountService, sanitizer);
  }

  ngOnInit(): void {
    this.getUser();
  }

  ngOnChanges(): void {
    this.getUser();
  }

  getHighlighted() {
    if (this.profile.userId === this.currentUser.id) {
      return `highlight ${this.controlClass}`;
    }
    return '';
  }
}
