import {Component, Input} from '@angular/core';
import {PollQuestionDto, PollQuestionStatus} from '@proxy/shared';
import {ZenBossBaseControlComponent} from '../../../zen-boss-base-control/zen-boss-base-control.component';
import * as moment from 'moment';

@Component({
  selector: 'lib-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
})
export class StatusBadgeComponent extends ZenBossBaseControlComponent {
  @Input() controlClass: string = 'default';
  @Input() poll: PollQuestionDto;

  elapsedText: string;
  isOpen: boolean = false;
  isDraft: boolean = false;

  ngOnInit(): void {
    const closedDate = this.poll.closedDate;
    const autoCloseDate = this.poll.autoCloseDate;

    if ((closedDate && moment().isAfter(closedDate)) || (!closedDate && moment().isAfter(autoCloseDate))) {
      this.elapsedText = `Concluded`;
    } else if (closedDate && moment().isBefore(closedDate)) {
      let daysDiff = closedDate.diff(moment(), 'days');
      if(daysDiff < 1){
        this.elapsedText = `${closedDate.diff(moment(), 'hours')} hours to go`;
      } else{
        this.elapsedText = `${daysDiff} days to go`;
      }
      this.isOpen = true;
    } else if (!closedDate && moment().isBefore(autoCloseDate)) {
      let daysDiff = autoCloseDate.diff(moment(), 'days');
      if(daysDiff < 1){
        this.elapsedText = `${autoCloseDate.diff(moment(), 'hours')} hours to go`;
      } else{
        this.elapsedText = `${daysDiff} days to go`;
      }
      this.isOpen = true;
    } else {
      this.elapsedText = 'Concluded';
    }

    if (this.poll.status == PollQuestionStatus._0) {
      this.elapsedText = 'Draft'; //overriding the text for draft poll
      this.isDraft = true;
    }
  }
}
