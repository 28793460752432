import type {GetQuestionsInput} from './models';
import {RestService} from '@abp/ng.core';
import type {PagedResultDto} from '@abp/ng.core';
import {Injectable} from '@angular/core';
import {PollQuestionCreateDto, PollQuestionDto, PollQuestionUpdateDto} from '@proxy/shared';

@Injectable({
  providedIn: 'root',
})
export class PollQuestionService {
  apiName = 'ZenBoss';
  area = 'zen-boss';
  controller = 'poll-questions';

  create = (input: PollQuestionCreateDto, publish: boolean = false) =>
    this.restService.request<any, PollQuestionDto>(
      {
        method: 'POST',
        url: `/api/${this.area}/${this.controller}/${publish}`,
        body: input,
      },
      {apiName: this.apiName}
    );

  update = (id: string, input: PollQuestionUpdateDto, publish: boolean = false) =>
    this.restService.request<any, PollQuestionDto>(
      {
        method: 'PUT',
        url: `/api/${this.area}/${this.controller}/update/${id}/${publish}`,
        body: input,
      },
      {apiName: this.apiName}
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/${this.area}/${this.controller}/${id}`,
      },
      {apiName: this.apiName}
    );

  get = (id: string) =>
    this.restService.request<any, PollQuestionDto>(
      {
        method: 'GET',
        url: `/api/${this.area}/${this.controller}/${id}`,
      },
      {apiName: this.apiName}
    );

  getForEdit = (id: string) =>
    this.restService.request<any, PollQuestionUpdateDto>(
      {
        method: 'get',
        url: `/api/${this.area}/${this.controller}/getForEdit/${id}`
      },
      {apiName: this.apiName}
    )

  getList = (input: GetQuestionsInput) =>
    this.restService.request<any, PagedResultDto<PollQuestionDto>>(
      {
        method: 'GET',
        url: `/api/${this.area}/${this.controller}`,
        params: {
          filterText: input.filterText,
          title: input.title,
          questionBody: input.questionBody,
          status: input.status,
          closeReason: input.closeReason,
          closedDateMin: input.closedDateMin,
          closedDateMax: input.closedDateMax,
          closedBy: input.closedBy,
          openedDateMin: input.openedDateMin,
          openedDateMax: input.openedDateMax,
          openedBy: input.openedBy,
          openedByName: input.openedByName,
          closedByName: input.closedByName,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      {apiName: this.apiName}
    );

  constructor(private restService: RestService) {
  }
}
