import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { VoteDto } from '../../models/vote-dto';
import { AuthService, ConfigStateService, SessionStateService } from '@abp/ng.core';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import { eThemeLeptonComponents } from '@volo/abp.ng.theme.lepton';
import { IdentityUserDto, IdentityUserService } from '@volo/abp.ng.identity/proxy';

@Component({
  selector: 'lib-vote-up-down',
  templateUrl: './vote-up-down.component.html',
  styleUrls: ['./vote-up-down.component.scss'],
})
export class VoteUpDownComponent implements OnInit, OnChanges {
  @Input() vote = {} as VoteDto;
  @Output() voteRequest = new EventEmitter<VoteDto>();

  currentUserImageComponentKey = eThemeLeptonComponents.CurrentUserImage;
  currentUser = this.configState.getOne('currentUser');
  identityUserDto = {} as IdentityUserDto;

  constructor(
    private authService: AuthService,
    private sessionState: SessionStateService,
    private configState: ConfigStateService,
    private identity: IdentityUserService
  ) {}

  ngOnInit(): void {
    // if (this.vote.voterId != undefined && this.identityUserDto.id == undefined) {
    //   this.identity
    //     .get(this.vote.voterId)
    //     .pipe(finalize(() => this.ok()))
    //     .subscribe(identityUserDto => (this.identityUserDto = identityUserDto));
    // }
  }

  ngOnChanges(): void {
    // if (this.vote.voterId != undefined && this.identityUserDto.id == undefined) {
    //   this.identity
    //     .get(this.vote.voterId)
    //     .pipe(finalize(() => this.ok()))
    //     .subscribe(identityUserDto => (this.identityUserDto = identityUserDto));
    // }
  }

  upVote() {
    this.voteRequest.emit({ value: 1 } as VoteDto);
  }

  downVote() {
    this.voteRequest.emit({ value: -1 } as VoteDto);
  }

  isCurrent() {
    if (this.vote.votes != undefined) {
      let userVote = this.vote.votes.find(x => x.voterId === this.currentUser.id);
      if (userVote === undefined) {
        return '';
      }
      if (userVote.value === 1) {
        return 'highlight-up';
      } else if (userVote.value === -1) {
        return 'highlight-down';
      }
    }
    return '';
  }

  private ok(): void {}
}
