import {ModuleWithProviders, NgModule, NgModuleFactory} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ThemeSharedModule} from '@abp/ng.theme.shared';
import {PageModule} from '@abp/ng.components/page';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommercialUiModule} from '@volo/abp.commercial.ng.ui';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import {NgxValidateCoreModule} from '@ngx-validate/core';
import {CoreModule, LazyModuleFactory} from '@abp/ng.core';
import {PollQuestionsRoutingModule} from './poll-questions-routing.module';
import {PollQuestionsComponent} from './poll-questions.component';
import {API_BASE_URL, PollQuestionServiceProxy, UserServiceProxy} from '@proxy/shared';
import {environment} from 'projects/dev-app/src/environments/environment';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {SharedModule} from '../shared/shared.module';
import {QuestionsModule} from '../questions/questions.module';
import {ViewPollComponent} from "./view-poll/view-poll.component";
import {CreatePollComponent} from "./create-poll/create-poll.component";

@NgModule({
  declarations: [
    PollQuestionsComponent,
    ViewPollComponent,
    CreatePollComponent
  ],
  exports: [
    PollQuestionsComponent
  ],
  providers: [
    PollQuestionServiceProxy,
    UserServiceProxy,
    {
      provide: API_BASE_URL,
      useValue: environment.apis.ZenBoss.url
    },
  ],
  imports: [
    CommonModule,
    CKEditorModule,
    CoreModule,
    ThemeSharedModule,
    PageModule,
    FormsModule,
    PollQuestionsRoutingModule,
    ReactiveFormsModule,
    CommercialUiModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    SharedModule,
    QuestionsModule
  ]
})
export class PollQuestionsModule {
  static forChild(): ModuleWithProviders<PollQuestionsModule> {
    return {
      ngModule: PollQuestionsModule,
      providers: [],
    };
  }

  static forLazy(): NgModuleFactory<PollQuestionsModule> {
    return new LazyModuleFactory(PollQuestionsModule.forChild());
  }

}

export function loadPollQuestionsModuleAsChild() {
  return Promise.resolve(PollQuestionsModule);
}
