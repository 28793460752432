import { ConfigStateService } from '@abp/ng.core';
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ThemeLepton } from '@volo/abp.ng.theme.lepton';
import {  BehaviorSubject } from 'rxjs';
import { ProfilePictureImage, PROFILE_PICTURE } from '@volo/abp.commercial.ng.ui/config';

@Component({
  selector: 'lib-current-user-image',
  templateUrl: './current-user-image.component.html',
  styleUrls: ['./current-user-image.component.scss'],
})
export class CurrentUserImageComponent
  implements OnInit, ThemeLepton.CurrentUserImageComponentInputs
{
  @Input() classes: string;

  currentUser = this.configState.getOne('currentUser');
  constructor(
    @Inject(PROFILE_PICTURE) public profilePicture$: BehaviorSubject<ProfilePictureImage>,
    private configState: ConfigStateService,
  ) {}

  ngOnInit(): void {
    this.load();
  }

  load() {
    if(this.currentUser.name == undefined) {
      this.currentUser.name = this.currentUser.userName;
    }

    if (this.currentUser.surName == undefined) {
      this.currentUser.surName = this.currentUser.userName;
    }
  }
}
