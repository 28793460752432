<footer
  class="text-center text-lg-start bg-light text-muted"
>
  <hr />
  <section class="">
    <div class="container text-left">
      <div class="row">
        <div class="col-md-9 col-lg-9 col-xl-9 px-4 d-flex align-content-center">
          <div class="row">
            <div class="col-sm-12 col-lg-2 mx-auto my-auto py-2">
              <img class="m-0 p-0" src="../assets/logo-small.png" />
            </div>
            <div class="col-sm-12 col-lg-10 text-center align-middle d-flex align-content-center">
              <div class="mx-auto my-auto py-2">
                <p>When we ask ourselves “can it be done better”? And we answer “yes”.</p>
                <p class="bold" style="color: #362cad">Then it’s our duty to make it better</p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="kohde col-md-2 col-lg-2 col-xl-2 mx-auto my-4 mb-2 text-center">
          <h6 class="text-uppercase fw-bold mb-2">Company</h6>
          <p>
            <a href="#!" class="text-reset">About</a>
          </p>
          <p>
            <a href="javascript: void(0)" (click)="navigateToPrivacyPolicy()" class="text-reset"
              >Privacy Policy</a
            >
          </p>
          <p>
            <a href="#!" class="text-reset">Terms and Conditions</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Help</a>
          </p>
        </div> -->
        <!-- <div class="kohde col-md-2 col-lg-2 col-xl-2 mx-auto my-4 mb-2 text-center">
          <h6 class="text-uppercase fw-bold mb-2">Products</h6>
          <p>
            <a href="#!" class="text-reset">Teams</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Advertising</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Collectives</a>
          </p>
        </div> -->
        <div class="col-md-3 col-lg-3 col-xl-3 mx-auto my-4 mb-2 text-center">
          <div class="kohde">
            <a
              href="https://www.facebook.com/CompnetNetwork"
              target="_blank"
              class="mx-2 text-reset"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
            <a href="https://twitter.com/CompnetNetwork" target="_blank" class="mx-2 text-reset">
              <i class="fab fa-twitter"></i>
            </a>
            <!-- <a href="" class="mx-2 text-reset">
              <i class="fab fa-instagram"></i>
            </a> -->
            <a
              href="https://www.linkedin.com/company/compnet-network"
              target="_blank"
              class="mx-2 text-reset"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="kohde-bottom-footer col col-12 mt-4 py-4 text-center">
    <div class="container">
      <!-- <div class="d-flex justify-content-end"> -->
      <div class="d-flex justify-content-center">
        <div class="px-2">
          <a class="text-reset fw-bold small" href="#">Terms and Conditions</a>
          <span> | </span>
          <a
            class="text-reset fw-bold small"
            href="javascript: void(0)"
            (click)="navigateToPrivacyPolicy()"
            >Privacy Policy</a
          >
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="px-2 small">© 2022 The Compliance Network / All rights reserved</div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</footer>
